<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h5 class="mb-0"><b>{{ title }}</b></h5>
        <button type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
          data-bs-toggle="tooltip" data-bs-placement="bottom" :title="buttonTitle">
          <i class="fas fa-info"></i>
        </button>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="text-center col-md-5 col-lg-6">
          <div class="chart">
            <canvas :id="chartId" class="chart-canvas" height="243"></canvas>
          </div>
          <h4 class="font-weight-bold mt-n8">
            <span>{{ totalConsumption }}</span>
            <span class="text-sm d-block text-body">{{ consumptionUnit }}</span>
          </h4>
        </div>
        <div class="col-md-7 col-lg-6 mt-3 fixed-dimensions">
          <div class="table-responsive">
            <table class="table mb-0 mt-7 mt-lg-0 mt-md-0 align-items-center">
              <tbody>
                <tr v-for="(room, index) in rooms" :key="index">
                  <td>
                    <div class="px-2 py-0 d-flex">
                      <span :class="room.badgeClass">&nbsp;</span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="room.name">{{ truncateName(room.name) }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <i class="fas fa-rupee-sign text-xs me-1"></i>
                    <span class="text-xs font-weight-bold">{{ room.amount }}</span>
                    <span class="text-xs font-weight-bold ms-1">({{ room.percentage }}%)</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "ReportsDoughnutChart",
  props: {
    buttonTitle: String,
    title: String,
    totalConsumption: String,
    consumptionUnit: String,
    rooms: Array,
    chartId: {
      type: String,
      required: true,
    },
  },
  methods: {
    truncateName(name) {
      if(name){
        let formattedName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
      return formattedName.length > 20 ? formattedName.substring(0, 10) + '..' : formattedName;
      }
    return "";
    },
    initializeChart() {
      if (!this.rooms || this.rooms.length === 0) {
        console.warn('No rooms data available');
        return;
      }
      var chart = document.getElementById(this.chartId);
      if (!chart) {
        console.error('chart not found');
        return;
      }
      var ctx1 = chart.getContext("2d");
      if (!ctx1) {
        console.error('Canvas context not found');
        return;
      }

      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

      let chartStatus = Chart.getChart(this.chartId);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      } 


      new Chart(ctx1, {
        type: "doughnut",
        data: {
          labels: this.rooms.map(room => room.name),
          datasets: [
            {
              label: "Consumption",
              weight: 9,
              cutout: 90,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: [
                "#FFADC6",
                "#B84B18",
                "#946E00",
                "#628515",
                "#009455",
              ],
              data: this.rooms.map(room => room.percentage),
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              boundary: 'chartArea'
            }
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    }
  },
  mounted() {
    this.initializeChart();
  },
  watch: {
    rooms: {
      handler(newValue) {
        console.log('Rooms data changed:', newValue);
        this.initializeChart();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
