<template>
  <div class="container-fluid px-5">
    <div  v-if="tblData.length > 0" class="col-12">
      <div class="row d-none d-md-flex">
        <div class="col-4 h-50 d-flex justify-content-center align-middle">
          <p class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Scheme</p>
        </div>
        <div class="col-2 h-50 d-flex justify-content-center align-middle">
          <p class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Scheme Type</p>
        </div>
        <div class="col-2 h-50 d-flex justify-content-center">
          <p class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Launch Date</p>
        </div>
        <div class="col-2 h-50 d-flex justify-content-center">
          <p class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">End Date</p>
        </div>
        <div class="col-2 h-50 d-flex justify-content-center"></div>
        <hr class="horizontal dark">
      </div>
      <div v-for="(item, index) in tblData" :key="index">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-md-4 d-flex flex-col justify-content-start">
            <div class="text-sm font-weight-bolder text-capitalize d-flex flex-column justify-content-center ms-2 text-truncate">
              <span class="mb-0 text-truncate text-sm font-weight-bolder text-capitalize">{{ item.schemeName.toLowerCase() }}</span>
            </div>
            <div class="px-1" v-if="item.soon"><span class="badge badge-danger badge-sm text-white">Closing Soon</span></div>
          </div>
          <div class="col-12 col-md-2 h-50 pt-2 pt-md-0 d-flex justify-content-center align-items-center">
            <span class="mb-0 text-sm text-secondary pe-2 d-flex d-md-none font-weight-bolder opacity-7">Scheme Type:</span>
            <p class="mb-0 text-sm text-secondary text-capitalize">{{ item.schemeType.toLowerCase() }}</p>
          </div>
          <div class="col-12 col-md-2 h-50 pt-2 pt-md-0 d-flex justify-content-center align-items-center">
            <span class="mb-0 text-sm text-secondary text-uppercase pe-2 d-flex d-md-none font-weight-bolder opacity-7">Start Date:</span>
            <p class="mb-0 text-sm text-secondary">{{ item.startDate }}</p>
          </div>
          <div class="col-12 col-md-2 h-50 py-2 py-md-0 d-flex justify-content-center align-items-center">
            <span class="mb-0 text-sm text-secondary text-uppercase pe-2 d-flex d-md-none font-weight-bolder opacity-7">End Date:</span>
            <p class="mb-0 text-sm text-secondary">{{ item.endDate }}</p>
          </div>
          <div class="col-12 col-md-2 h-50 d-flex justify-content-center">
            <a class="btn btn-link text-primary px-3 mb-0" @click="showDetails(index)">More Info <i :class="[currentEvent == index ? 'fa fa-angle-up' : 'fa fa-angle-down']"></i></a>
          </div>
        </div>
        <Collapse :when="currentEvent == index" class="v-collapse">
          <div class="mx-5 my-3">
            <div class="row">
              <div class="col-12 text-center"><span class="m-0 font-weight-bold">{{ item.schemeName }}</span></div>
            </div>
            <div class="row my-2">
              <div class="col-12">
                <div class="text-justify text-sm">{{ item.objective }}</div>
              </div>
            </div>
            <div class="row">
              <div class="m-auto col-12 col-md-8 d-flex-column d-md-flex justify-content-around">
                <div class="flex-column mt-md-0 mt-2 my-auto">
                  <p class="m-0 text-center text-sm text-primary font-weight-bold"><i class="fa fa-gauge-simple"></i> Riskometer</p>
                  <h6 class="m-0 text-center text-sm">{{ item.riskometerLaunch }}</h6>
                </div>
                <div class="flex-column mt-2 mt-md-0 my-auto">
                  <p class="mb-0 text-center text-sm text-primary font-weight-bold"><i class="fa fa-calendar"></i> NAV</p>
                  <h6 class="mb-0 text-center text-sm">₹ {{ typeof item.navValue == "number" ? item.navValue.toFixed(2) : item.navValue }}</h6>
                </div>
                <div class="flex-column mt-2 mt-md-0 my-auto">
                  <p class="mb-0 text-center text-sm text-primary font-weight-bold"><i class="fa fa-coins"></i> Min Investment</p>
                  <h6 class="mb-0 text-center text-sm">₹ {{ typeof item.minimumPurchaseAmount == "number" ? item.minimumPurchaseAmount.toFixed(2) : item.minimumPurchaseAmount }}</h6>
                </div>
              </div>
              <productModal :modalTitle="item.schemeName" :minimumInvestment="item.minimumPurchaseAmount" :navValue="item.navValue"
                :actionType="currentActionType" :schemeCode="item.schemeCode" :uniqueNumber="item.uniqueNumber" :mandateData="mandateData"></productModal>
                <mandateModal id="mandateModal"></mandateModal>
              <div class="col-md-4 col-12 d-flex justify-content-around py-4">
                <soft-button color="primary" class="wid" size="sm" @click="handleAction('One-Time', item.uniqueNumber)">BUY</soft-button>
                <soft-button color="primary" class="wid" size="sm"  @click="handleAction('SIP', item.uniqueNumber)">SIP</soft-button>
              </div>
            </div>
          </div>
        </Collapse>
        <hr class="horizontal dark">
      </div>
    </div>
    <div v-else class="col-12 justify-content-center  text-center py-5">
      <h6>NFO's are not available at the moment, please explore our latest mutual funds. <router-link to="/explore"
          class="text-primary"><u>Explore</u></router-link></h6>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import productModal from "@/views/common/components/ProductModal.vue";
import { Collapse } from 'vue-collapsed';
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import mandateModal from "@/components/MandateAuthorization.vue";

export default {
  name: "NfoList",
  components: {
    SoftButton,
    Collapse,
    productModal,
    mandateModal
  },
  data() {
    return {
      currentEvent: -1,
      currentActionType: '',
      message: "createUser",
    };
  },
  props: {
    tblData: {
      type: Array,
      default: () => [],
    },
    mandateData: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default:"",
    }
  },
  methods: {
    showDetails(index) {
      this.currentEvent = (index === this.currentEvent ? -1 : index);
    },
    handleAction(type, uniqueNumber) {
      if (this.isLoggedIn()) {
        if (this.isMandatesRegistrationCompleted() && this.status=="APPROVED") {
          this.setActionType(type);
          this.showProductModal(uniqueNumber);
        } else {
          this.hideModal();
          this.showMandateModal();
        }
      } else {
        this.hideModal();
        this.redirectToSignup();
      }
    },
    setActionType(type) {
      this.currentActionType = type;
    },
    isLoggedIn() {
      return sessionStorage.getItem('accessToken');
    },
    isMandatesRegistrationCompleted() {
      const userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
      const userStatus = userPayload?.['custom:user_signup_status'];
      return userStatus === 'MANDATE_REGISTRATION_COMPLETED';
    },
    hideModal() {
      const modalElement = document.querySelector(`[id^="modalBuy"]`);
      if (modalElement) {
        const modal = bootstrap.Modal.getInstance(modalElement);
        if (modal) {
          modal.hide();
        }
        // Remove modal backdrop
        document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
        document.body.classList.remove('modal-open');
        document.body.style.paddingRight = '';
      }
    },
    showProductModal(uniqueNumber) {
      const modalElement = document.querySelector(`#modalBuy${uniqueNumber}`);
      if (modalElement) {
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }
    },
    showMandateModal() {
      const mandateModalElement = document.getElementById('mandateModal');
      if (mandateModalElement) {
        const modal = new bootstrap.Modal(mandateModalElement);
        modal.show();
      }
    },
    redirectToSignup() {
      this.$router.push({ name: 'Sign In', params: { message: this.message } });
      this.cleanUpModal();
    },
  },
};
</script>

<style>
.v-collapse {
  transition: height 300ms cubic-bezier(0, 0, 0, 0);
}
</style>
