<template>
    <div class="position-fixed  top-0 end-0 p-3 pt-5" style="z-index: 12">
    <div id="liveToast" class="toast  " role="alert" aria-live="assertive" aria-atomic="true">
      <div class="alert toast-header bg-primary ">
        <strong class="ms-auto text-white"> Great Choice! We'll be in touch shortly</strong>
        <button type="button" class="btn btn-link text-white my-1" data-bs-dismiss="toast"><i
            class="fas fa-times"></i></button>
      </div>
    </div>
  </div>
</template>