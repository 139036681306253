<template>
  <div class="card">
    <div class="p-3 card-body">
      <div class="d-flex" :class="directionReverse ? reverseDirection : ''">
        <div>
          <div
            class="text-center shadow icon icon-shape border-radius-md"
            :class="typeof icon === 'object' ? icon.background : ''"
          >
            <i
              class="text-lg opacity-10"
              :class="typeof icon === 'string' ? icon : icon.component"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <p
              class="mb-0 text-md text-capitalize font-weight-bold"
              :class="title.color"
            >
              {{  title.text }}
            </p>
            <h6 class="mb-0 font-weight-bolder" :class="value.color">
              
              {{
                typeof value.text === "string" || typeof value.text   === "number"
                  ?value.prepend+" "+value.text+" "+value.apend:'Not Available'
              }}
              <span
                class="text-sm font-weight-bolder"
                :class="percentage.color"
              >
                {{
                  typeof percentage === "number" ||
                  typeof percentage === "string"
                    ? `${percentage}`
                    : ""
                }}
                {{
                  percentage && percentage === "object"
                    ? `${percentage.value}`
                    : ""
                }}
              </span>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    directionReverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [Object, String],
      default: null,
      text: {
        type: String,
      },
      color: {
        type: String,
      },
    },
    value: {
      type: [Object, String, Number],
      required: true,
      text: {
        type: [String, Number],
        default:" ",
      },
       prepend: {
        type: String,
        default: ' ',
      },
      apend: {
        type: String, 
        default: ' ',
      },
      color: {
        type: String,
      },
    },
    percentage: {
      type: [Object, String],
      value: {
        type: String,
      },
      color: {
        type: String,
      },
      default: () => ({
        color: "text-success",
      }),
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
      }),
    },
    classContent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
};
</script>
