<template>
  <div class="card">
    <div class="p-3 pt-2 pb-0 card-header mh-415">
        <h6 class="my-77">Sector Allocation</h6>
    </div>
    <div class="p-4  pb-0  card-body">
      <div class="row">
        <div class="text-center col-lg-5 col-12 mh-300"   v-if="chartData">
          <div class="chart" >
            <div class="chart-container mh-300">
            <canvas
              id="chart-consumption"
              class="chart-canvas"
              style="height:300px !important;"
            ></canvas>
            </div>
          </div>
          <h4 class="font-weight-bold mt-n9" v-if="chartData && chartData.length>0">
            <span>{{total}}</span>
            <span class="text-sm d-block text-body">Crores</span>
          </h4> 
        </div>
        <div class="p-0 d-flex align-items-center justify-content-center position-absolute" style="height:300px;"  v-if="!chartData || chartData.length==0">Sectorwise breakdown not available.</div>
        <div class="col-lg-7 col-12 d-flex align-items-center justify-content-center"   v-else>
          <div class="table-responsive w-100">{{ legendColor }}
            <table class="table mb-0 align-items-center">
              <tbody>
                <tr  v-for="index of numOfElem"
                  :key="index" > 
                  <td>
                    <div class="px-2 py-0 d-flex">
                      <span class="badge  me-2"  v-bind:style="styleObject[index-1]"
                    >&nbsp;</span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{chartLabel[index-1]}}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold">{{chartData[index-1]}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "SectorDoughnutChart",
  mounted() {
   
  },
  data() {
      return {
     backgroundColor:[
              "#FFADC6",
              "#B84B18",
              "#946E00",
              "#628515",
              "#009455",
            ],
styleObject:[ { "background-color":'#FFADC6',},
{ "background-color":'#B84B18'},
{ "background-color":'#946E00'},
{ "background-color":'#628515'},
{ "background-color":'#009455'}]
      }
    },
    watch: {
      chartData:{ 
        handler() {
if(document.getElementById("chart-consumption") )
{
        var ctx1 = document.getElementById("chart-consumption").getContext("2d");
    var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);
    gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
    gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");
    let chartStatus = Chart.getChart("chart-consumption");
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    new Chart(ctx1, {
      type: "doughnut",
      data: {
        labels:this.chartLabel,
        datasets: [
          {
            label: "Consumption",
            weight: 9,
            cutout: 90,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 2,
            backgroundColor:this.backgroundColor,
            data:this.chartData,
            fill: false,
            dss:"this.chartData",
          },
        ],

      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 1,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
       
    });
  }
      },
      deep: true
      },
  },
  props: {
          name: {
            type: String,
            required: true,
          },
          id: {
            type: String,
            required: true,
          },
          numOfElem: {
            type: Number,
            required: true,
          },
          total: {
            type: Number,
            required: true,
          },
          checked: {
            type: Boolean,
            default: false,
          },
          chartData:{
            type:Array,
          },
          chartLabel:{
            type:Array,
          },
          validData:{
            type:Boolean,
          }
        },
};
</script>
<style>
.mh-300{
  min-height: 300px !important;
}
.mh-415{
  min-height: 57.594px !important;
}
.my-77{
  margin-top: 7.797px !important;
  margin-bottom: 7.797px !important;
}
</style>
