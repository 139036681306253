<template>
    <div class="modal fade" id="mandateModal" tabindex="-1" role="dialog" aria-hidden="true" data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm px-4 px-md-0" role="document">
            <div class="modal-content">
                <div class="modal-header border-0 d-flex justify-content-start pb-0 px-4">
                    <h6 class="modal-title ms-1" id="modal-title-notification"></h6>
                </div>
                <div class="py-0 my-0 px-4 ms-1">
                    <small class="d-block text-xxs"></small>
                </div>
                <div class="modal-body p-0 h-100">
                    <div class="card card-plain modal-card">
                        <div>
                            <div class="mt-5 align-item-center ms-1">
                                <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                                </svg>
                            </div>
                            <div class="card-body ps-4 py-3">
                                <div>
                                    <h4 class="text-center me-4 ps-4 mb-3 text-capitalize">Order Placement failed !</h4>
                                    <p class="text-center text-xxs pt-2 text-capitalize ps-2 text-danger">Mandate not
                                        approved, please try again later.</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center mt-3 mx-4">
                                <soft-button class="text-white bg-primary border border-primary w-100"
                                    data-bs-dismiss="modal">Close</soft-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
export default {
    name: "MandateAuthorization",
    components: {
        SoftButton,
    },
};
</script>