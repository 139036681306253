<template>
  <div class="col-lg-3 col-6 text-center p-1">
    <div class="border-dashed border-1 border-secondary border-radius-md py-3 h-100">
      <h5 class="text-primary mb-0">{{ title }}</h5>
      <h4 class="font-weight-bolder">
        <span class="small">{{ prefix }}</span>
        {{ "" }}
        <countTo :start-val="0" :end-val="count" :duration="duration" :decimals="2"></countTo>
        {{ "" }}
        <span class="small">{{ suffix }}</span>
      </h4>
      <h5 v-if="subTitle">(
        {{ "" }}
        <span class="small">{{ subTitle }}</span>
        {{ "" }}
        <span class="small">{{ subTitleSuffix }}</span>)</h5>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to/src";

export default {
  name: "TransparentInfoCard",
  components: {
    countTo,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    duration: {
      type: Number,
      default: 3000,
    },
    subTitle: { 
      type: String,
      default: ''
    },
    subTitleSuffix: {
      type: String,
      default: "",
    },
  },
};
</script>
