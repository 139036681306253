<template>
     <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div class="modal-dialog modals-dialog modal-danger modal-dialog-centered modal-" role="document">
        <div class="modal-content modals-content modals bg-primary">
          <div class="modal-header border-0 d-flex justify-content-end">
            <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="row">
             <div class="col-5 d-none d-sm-none d-md-block">
              <img class="background-img"  src="..//assets/img/popup.png" alt="..." >
             </div>
           <div class="col-md-7 col-sm-12 align-items-center justify-content-center ps-5 pe-5">
            <div class="py-2 text-center">
              <i class="fa-solid fa-phone text-white fa-2xl" aria-hidden="true"></i>
              <p class="  text-white  mt-4 "><b>Experience Financial Prosperity Today with OneS  </b> </p>
              <h1 class="heading text-white">+91 9895 204 233</h1>
              <div class="text-center py-3">
                <button type="button" class="btn bg-red pe-auto  text-white btn-round  shadow-none" onclick="window.location = 'tel:+91 9895 204 233'" >Call us now</button>
              </div>
            </div>
          </div>
            </div>
        </div>
      </div>
    </div>
</template>