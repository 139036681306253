<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <!-- nav -->
    <navbar />
    <div class="min-vh-100">
      <router-view />
    </div>
    <div v-if="isSpinnerActive" class="blur-background">
      <div class="d-flex flex-column align-items-center justify-content-center pt-12 mt-7">
        <VueSpinnerDots size="50" color="#C71C4E" />
      </div>
    </div>
    <div>
      <banner />
      <app-footer />
    </div>
  </main>
</template>
<script>
import Navbar from "@/views/Navbars/Navbar.vue";
import AppFooter from "@/components/Footer.vue";
import Banner from "@/components/BannerFooter.vue";
import { mapState } from "vuex";
import { VueSpinnerDots } from 'vue3-spinners';
import { isOpenPath } from "@/components/web-interface/projectedPath";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "App",
  components: {
    Navbar,
    AppFooter,
    Banner,
    VueSpinnerDots
  },
  computed: {
    ...mapState('layoutStore', {
      isSpinnerActive: state => state.isSpinnerActive
    })
  },
  mounted() {
    this.intervalId = setInterval(() => {
      const tokenStatus = this.validateToken();
      const currentPath = this.$route.path;
      const isNonProtectedPath = isOpenPath(currentPath);
      if (tokenStatus === 'aboutToExpire') {
        this.refreshAccessToken();
      }

      if ((tokenStatus === 'expired' || tokenStatus === 'noToken') && !isNonProtectedPath) {
        sessionStorage.clear();
        this.hideAllModals();
        this.$store.commit('userStore/clearState');
        this.$router.push({ path: '/signin', query: { error: 'Expired' } });
      }
    }, 600000);
  },
  methods: {
    hideAllModals() {
      const modals = document.querySelectorAll('.modal.show');
      modals.forEach(modal => {
        const instance = bootstrap.Modal.getInstance(modal);
        if (instance) {
          instance.hide();
        }
      });
      const backdrops = document.querySelectorAll('.modal-backdrop');
      backdrops.forEach(backdrop => backdrop.remove());
    },
    validateToken() {
      return this.$store.getters['userStore/validateToken'];
    },
    refreshAccessToken() {
      let data = {
        "email": JSON.parse(sessionStorage.getItem('userPayload')).email,
        "refreshToken": sessionStorage.getItem("refreshToken"),
      };
      this.$store
        .dispatch("userStore/refreshAccessToken", data, { root: true })
    }
  },
  unmounted() {
    clearInterval(this.intervalid)
  }
};
</script>
