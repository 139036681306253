<template>
  <div class="banner d-flex justify-content-between align-items-center background-banner text-white">
    <div class="container position-relative z-index-2">
      <div class="row">
        <div class="col-lg-5 col-md-8 m-auto text-start">
          <h6 class="text-white mb-lg-0 mb-2">
            Unlock the Potential of Your Investments: Explore Our Range of Mutual Funds Today!
          </h6>
        </div>
        <div class="col-lg-6 m-auto">
          <div class="row">
            <div class="col-sm-4 col-6 ps-sm-0 ms-auto">
              <button class="btn btn-light text-primary mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto d-block"  @click="redirect" >Explore</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  methods: {
    redirect() {
      this.$router.push("/explore");
    }
  }
}
</script>