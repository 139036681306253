import { createRouter, createWebHashHistory } from "vue-router";
import Explore from "../views/schemedetails/List.vue";
import NFO from "../views/nfo/Nfos.vue";
import SchemeDetail from "../views/schemedetails/Details.vue";
import OnesHome from "../views/homepage/OnesHome.vue";
import ProfileDashboard from "../views/dashboard/ProfileDashboard.vue";
import Portfolio from "../views/portfolio/Portfolio.vue";
import Signup from "../views/signup/Signup.vue";
import Signin from "../views/signin/Signin.vue";
import MobileVerification from "../views/verification/MobileVerification.vue";
import PancardVerification from "../views/ckyc/PancardVerification.vue";
import AdditionalInfo from "../views/ckyc/AdditionalInfo.vue";
import NomineeDetails from "../views/nominee/NomineeDetails.vue";
import BankaccountRegister from "../views/pennydrop/BankaccountRegister.vue";
import Emailverification from "../views/verification/EmailVerification.vue";
import Resetpassword from "../views/forgotpassword/Resetpassword.vue";
import Newpassword from "../views/forgotpassword/Newpassword.vue";
import store from "../store/index.js";
import OrderStatusTable from "../views/order/OrderStatusTable.vue";
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/ones-home",
  },
  {
    path: "/explore",
    name: "Explore",
    component: Explore,
  },
  {
    path: "/NFO",
    name: "NFO",
    component: NFO,
  },
  {
    path: "/details/:isin",
    name: "Scheme Detail",
    component: SchemeDetail,
  },
  {
    path: "/ones-home",
    name: "OneS Home",
    component: OnesHome,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component:ProfileDashboard , 
    beforeEnter: [isAuthenticated,isUserStatus],
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
    beforeEnter: [isAuthenticated,isUserStatus],
  },
    {
    path: "/signup",
    name: "Sign Up ",
    component: Signup,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: Signin,
  },
  {
    path: "/mobileverification",
    name: "Mobile Verification",
    component: MobileVerification,
  },
  {
    path: "/pancardverification",
    name: "Pancard Verification",
    component: PancardVerification,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/additionalinfo",
    name: "Additional Info",
    component:AdditionalInfo,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/nomineedetails",
    name: "Nominee Details",
    component: NomineeDetails,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/bankaccountregister",
    name: "Bankaccount Register",
    component:BankaccountRegister,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/emailverification",
    name: "Email Verification",
    component:Emailverification,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    component: Resetpassword,
  },
  {
    path: "/newpassword",
    name: "New Password",
    component: Newpassword,
  },
  {
    path: "/orderstatus",
    name: "Order Status",
    component: OrderStatusTable,
    beforeEnter: [isAuthenticated,isUserStatus],
  },
];

const router = createRouter({

  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { top: 0 };
  }
  
});
function isAuthenticated(to, from, next) {
  const isAuthenticated=store.getters['userStore/isLoggedIn'];
  if (isAuthenticated) {
      next();
  
  } else {
    next("/signin");
  }
}
function isUserStatus(to, from, next) {
  const isUserStatus=store.getters['userStore/getUserStatus'];
  if (isUserStatus==="EMAIL_VERIFIED"|| isUserStatus === "MANDATE_REGISTRATION_COMPLETED" || isUserStatus === "MANDATE_REGISTRATION_FAILED" || isUserStatus === "MANDATE_RETRY_INITIATED" || isUserStatus === "MANDATE_INITIATED") {
      next();
  
  } else {
    store
    .dispatch("userStore/logout", { root: true })
    .then(function (response) {
      console.log(response);
      next({
        name: 'Sign In',
        params: { message: 'linkFailed' }
      });
    })
    .catch(function (error) {
      console.log(error);
      next("/signin");
    });
  }
}

export default router;
