<template>
  <main class="mt-0 main-content">
    <div class="page-header min-vh-100">
      <div class="pt-6 container-fluid">
        <div class="row">
          <div
            class="col-5 d-lg-flex d-none h-100 my-auto ps-7 pe-0 position-relative top-0 end-0 text-center justify-content-center flex-column">
            <img class="w-100 position-relative z-index-2" src="@/assets/img/penny-pull.png" />
          </div>
          <div class="mx-xl-auto mx-auto col-xl-5 col-lg-6 col-md-6 d-flex flex-column mx-lg-0 ps-xl-7">
            <div class="card border shadow">
              <div class="card-body px-2 mx-xl-5">
                <h4 class="font-weight-bolder text-center mb-3 mt-2">Bank Account Verification</h4>
                <Form role="form" @submit="onSubmit">
                  <div class="mb-4 mb-sm-3 ms-xl-2 ms-2">
                    <label for="name" class="form-label label ms-2">Bank Name</label>
                    <soft-input id="bankName" type="text" rules="required:true" placeholder="Name of the bank"
                      v-model="name" name="Bank Name" :disabled="isVerified" />
                  </div>
                  <div class="mb-4 mb-sm-3 ms-xl-2 ms-2">
                    <label for="ifsc" class="form-label label ms-2">IFSC Code</label>
                    <soft-input id="ifsc" v-model="ifsc" type="text" rules="required:true|ifsc" placeholder="IFSC code"
                      name="IFSC Code" maxlength="11" :disabled="isVerified" />
                  </div>
                  <div class="mb-5 mb-sm-3 ms-xl-2 ms-2">
                    <label for="accountNumber" class="form-label label ms-2">Account Number</label>
                    <soft-input id="accountNumber" v-model="accountNumber" type="text" rules="min:6|required:true"
                      placeholder="Enter your account number" name="Account Number" maxlength="18" minlength="6"
                      :disabled="isVerified" />
                  </div>
                  <div class="mb-4 mb-sm-3">
                    <label for="type" class="form-label label ms-3">Account Type</label>
                    <soft-select v-model="type" id="account_type" name="Account type" :options="accountType"
                      rules="required:true" :isDisabled="isVerified">
                    </soft-select>
                  </div>
                  <div class="mb-4 mb-sm-3">
                    <label for="holdings" class="form-label label ms-3">Account Holdings</label>
                    <soft-select v-model="holdings" id="account_Holding" name="Account Holdings"
                      :options="accountHoldings" rules="required:true" :isDisabled="isVerified">
                    </soft-select>
                  </div>
                  <div class="text-end"><small class="mt-4 text-success text-xl" v-if="showVerificationMessage">Status:
                      <b> {{ bankStatus }} </b></small>
                  </div>
                  <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error
                      }}</small>
                  </div>
                  <div class="text-center ms-xl-2 mb-2">
                    <soft-button class="mt-4 w-100" color="primary" type="submit">{{ buttonText }}</soft-button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftSelect from "@/views/common/components/SoftSelect.vue"
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
export default {
  name: "bankaccountRegistration",
  components: {
    SoftInput,
    SoftButton,
    SoftSelect,
    Form,
  },
  data() {
    return {
      name: "",
      ifsc: "",
      accountNumber: "",
      type: "",
      holdings: "",
      accountType: [
        { value: "Select account type", text: "Select account type" },
        { value: "SAVINGS_BANK", text: "SAVINGS BANK" },
        { value: "CURRENT_BANK", text: "CURRENT BANK" },
        { value: "NRE", text: "NRE" },
        { value: "NRO", text: "NRO" },
      ],
      accountHoldings: [
        { value: "Select account holding", text: "Select account holding" },
        { value: "SINGLE_BANK_ACCOUNT", text: "SINGLE BANK ACCOUNT" },
        { value: "JOINT_BANK_ACCOUNT", text: "JOINT BANK ACCOUNT" },
      ],
      isVerified: false,
      showVerificationMessage: false,
      buttonText: 'Verify',
      bankStatus: "",
      isSuccess: true,
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.email = userPayload.email;
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect() {
      this.$router.push("/emailverification");
    },
    onSubmit() {
      if (this.isVerified) {
        this.redirect();
      } else {
        let data = {
          "email": this.email,
          "accountNumber": this.accountNumber,
          "ifsc": this.ifsc,
          "bankName": this.name,
          "accountType": this.type,
          "accountHoldings": this.holdings,
        };
        var self = this;
        this.$store.dispatch("userStore/pennyDrop", data, { root: true })
          .then(function (response) {
            console.log(response);
            self.isSuccess = true;
            self.bankStatus = "Verified"
            self.isVerified = true;
            self.showVerificationMessage = true;
            self.buttonText = 'Proceed';
          })
          .catch(function (error) {
            console.log(error);
            self.showVerificationMessage = true;
            self.isSuccess = false;
            self.bankStatus = "Failed"
            self.error = error.message || 'Bank account verification failed. Please check your details and retry.';
          });
      }
    },
  }
};
</script>
