import { defineRule } from "vee-validate";
import * as rules from "@vee-validate/rules";
// Password strength validator
Object.keys(rules).forEach((rule) => {
    defineRule(rule, rules[rule]);
  });
defineRule('password', (value) => {
  if (!value) {
    return "A password is required.";
  }
  if (value.length < 8) {
    return "The password must be at least 8 characters long.";
  }
  if (!/[A-Z]/.test(value)) {
    return "The password must contain at least one uppercase letter.";
  }
  if (!/[a-z]/.test(value)) {
    return "The password must contain at least one lowercase letter.";
  }
  if (!/[0-9]/.test(value)) {
    return "The password must contain at least one number.";
  }
  if (!/[^A-Za-z0-9]/.test(value)) {
    return "The password must contain at least one special character.";
  }
  return true;
});
defineRule('phone', value => {
  if (!value) {
    return "The Phone number is required";
  }
  if (!/^\d{10}$/.test(value)) {
    return "The Phone Number must contain exactly 10 numbers";
  }
  return true;
});
defineRule('email', value => {
  if (!value) {
    return "The email must be valid.";
  }
  return true;
});
defineRule('ifsc', value => {
  if (!value) {
    return "The IFSC Code is required";
  }
  if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
    return "Invalid IFSC Code format";
  }
  return true;
});
import { configure } from "vee-validate";
configure({
  validateOnInput: true,
});
