<template>
  <section class="py-sm-7 py-6">
    <div class="container ">
      <div class="row">
        <div class="col-lg-5">
          <h3 class="mt-2">Invest with Trust and Expertise</h3>
          <p class="text-justify">
            Welcome to OneS, where our journey in the Mutual Funds market is marked by over 15 years of dedicated service
            and innovation. Our expertise, honed through years of navigating the complexities of Financial markets, stands
            as a testament to our commitment to excellence.
          </p>
          <a href="https://ones.money/" target="blank" class="text-primary  icon-move-right">Know More
            <i class="fas fa-arrow-right text-xs ms-1"></i>
          </a>
        </div>
        <div class="col-lg-6 mt-lg-0 mt-5 ps-lg-0 ps-0 ms-auto">
          <div class="p-3 info-horizontal d-flex align-items-center">
              <i class="fa-solid fa-puzzle-piece text-primary" aria-hidden="true"></i>
            
            <div class="description ps-3">
              <h4 class="mb-0">Tailored Investment Plans </h4>
            </div>
          </div>
          <div class="p-3 info-horizontal d-flex align-items-center">
              <i class="fa-solid fa-pie-chart text-primary" aria-hidden="true"></i>
            <div class="description ps-3">
              <h4 class="mb-0">Diverse Portfolio Options</h4>
            </div>
          </div>
          <div class="p-3 info-horizontal d-flex align-items-center">
              <i class="fa-solid fa-magnifying-glass text-primary" aria-hidden="true"></i>
            <div class="description ps-3">
              <h4 class="mb-0">Data-Driven Insights</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
