<template>
  <div class="card bg-gradient-white mt-4">

    <div class="modal fade" id="disclaimerPopup" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Disclaimer</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <p class="text-sm">Mutual Fund investments are subject to market risks. 
        Please read all scheme related documents carefully before investment. 
        Past performance is not an indicator for future returns.
</p>
<p class="text-sm">The performance figures are for representational purpose only and do not offer any guarantees. 
  The performance have been calculated based on historic returns of mutual funds.
</p>
      </div>
    
    </div>
  </div>
</div>
    <div class="bg-transparent card-header">
      <div class="row">
        <div class="col-lg-4 col-12">
          <div class="d-flex align-items-center position-relative  justify-content-center logo-wrapper-height">
            <img class="py-2 logo-height" :src="img"/>
            <hr class="mt-0 vertical dark d-none d-lg-block" />
          </div>
        </div>
        <div class="col-lg-4 col-12 d-flex align-items-center position-relative justify-content-center">
          <div class="d-flex align-items-center position-relative justify-content-center">
            <div class="row flex-column d-flex justify-content-center align-items-center">
              <div class="col-12 d-flex justify-content-center align-items-center">Fund Managers</div>
              <div class="col-12 d-flex justify-content-center align-items-center">
                <h6 class="mb-0" v-if="subtitle.length!=0">{{subtitle}}</h6>
                <h6 class="mb-0" v-else>Not available</h6>
              </div>
            </div>
          </div>
          <hr class="mt-0 vertical dark d-none d-lg-block" />
        </div>
        <div class="col-lg-4 col-12 align-self-center">
          <div class="row">
            <div class="col-6 middle-a"><a
                :href="schemeDocumentUrl"
                class="text-muted"
                target="_blank"
                >Scheme Documents</a
              ></div>
            <div class="col-6 middle-a">
              <a
                href="#"
                class="text-muted"
                data-bs-toggle="modal" data-bs-target="#disclaimerPopup"
                >Disclaimer</a
              ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterCard",
  data() {
    return {
      map: null,
      tiles: [],
    };
  },
  mounted() {
  },
  props: {
    subtitle: {
      type: String,
      default:"Not available",
      required: true,
    },
    img:{
      type: String,
      required: true,
    },
    schemeDocumentUrl:{
      type: String,
    },
    }

};
</script>
<style scoped>
.logo-wrapper-height{
  max-height: 100px !important;
}
.logo-height{
  max-height: 100px !important;
}
.middle-a{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
