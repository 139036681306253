
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import {useLoading} from 'vue3-loading-overlay';
import validation from "./utility/validation/validation";
import Password from "./utility/validation/password";
import locales from  "./utility/error/locales";
import { defineRule } from "vee-validate";
defineRule("confirmed", (value, [target], ctx) => {
    if (value === ctx.form[target]) {
      return true;
    }
    return "Passwords must match";
  });
  const checkTokenExpiration = () => {
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    if (userPayload) {
      const tokenExpiration = userPayload.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime >= tokenExpiration) {
        store.state.userStore.accessToken=null;
        sessionStorage.clear();
        router.push("/signin");
      }
    }
  };
  setInterval(checkTokenExpiration, 60000); 
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(useLoading);
appInstance.use(locales);
appInstance.use(validation);
appInstance.use(Password);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
