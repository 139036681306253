<template>
  <div class="mb-4 text-center text-justify p-0 text-muted ">
    <span class="text-sm">Please enter the 6-digit OTP sent to {{ maskedEmail }}</span>
  </div>
  <Form role="form" @submit="onSubmit">
    <div class="row gx-2 gx-sm-3">
      <soft-input class="mb-0" placeholder="Enter OTP" v-model="otp" name="otp" id="businessName" type="text"
        rules="required:true" maxlength="6" />
    </div>

    <div class="d-flex justify-content-center align-items-center text-sm mb-3 mt-4 flex-column flex-sm-row">
      <a id="resend" href="javascript:;" @click="resend"
        class="text-info font-weight-bolder ms-sm-1 mt-2 mt-sm-0 text-sm">Resend OTP</a>
      <span v-if="timer > 0" class="ms-sm-1 mt-2 mt-sm-0 text-xxs">in {{ timer }} seconds</span>
    </div>
    <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error
        }}</small>
    </div>
    <div class=" d-flex justify-content-center mt-lg-6 mt-md-6 mt-5 pt-2  pt-md-5 ">
      <soft-button class="text-primary bg-white border border-primary me-4 w-100"
        @click.prevent="redirect">previous</soft-button>
      <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary" type="submit"> Verify
      </soft-button>
    </div>
  </Form>
</template>
<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { mapMutations } from "vuex";
import { Form } from "vee-validate";
export default {
  name: "TwoFactorAuth",
  components: {
    SoftButton,
    SoftInput,
    Form,
  },
  data() {
    return {
      otp: "",
      count: 60,
      time: "",
      resendEnable: true,
      email: "",
      resetPassword: true,
      error: "",
      showModal: true,
    }
  },
  mounted() {
    this.$store.state.showFooter = false;
    this.$store.state.showSidenav = false;
    this.setTime();
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.email = userPayload.email;
  },
  computed: {
    timer() {
      return this.count;
    },
    maskedEmail() {
      if (this.email) {
        let parts = this.email.split('@');
        let name = parts[0];
        let domain = parts.length > 1 ? parts[1] : '';
        let maskedName = name.substring(0, 1) + name.substring(1).replace(/./g, '*');
        return `${maskedName}@${domain}`;
      }
      return '';
    },
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    clearInterval(this.time);
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    setTime() {
      clearInterval(this.time);
      document.getElementById("resend").classList.remove("text-info");
      document.getElementById("resend").classList.add("text-secondary");
      this.resendEnable = false;
      this.count = 60;
      this.time = setInterval(this.myTimer, 1000);
    },
    myTimer() {

      this.count--;
      if (this.count == 0) {
        document.getElementById("resend").classList.remove("text-secondary");
        document.getElementById("resend").classList.add("text-info");
        this.resendEnable = true;
        clearInterval(this.time);
      }
    },
    onSubmit() {
      this.$emit('verification-success', this.otp);
    },
    redirect() {
      this.$emit('previous');
    },
    resend() {
      this.$emit('resent-otp');

    },
  }
};
</script>