<template>
  <div class="container-fluid py-5 pt-8">
    <div class="row">
      <div class="col-xxl-6">
        <detail-card :title="schemeName" :category="category" :description="description" :avatarName="schemeName"
          :minimumInvestment="minimumInvestment" :navValue="navValue" :schemeCode="schemeCode"
          :uniqueNumber="uniqueNumber" />
      </div>
      <div class="col-xxl-2 col-xl-6 mt-4 mt-xxl-auto">
        <nav-card id="chart-widgets" title="Current NAV" :lastModifiedDate="lastModifiedDate" :value="{
          amount: navValue,
          percentage: { value: (navChange > 0 ? '+' : '') + navChange + '%', color: (navChange > 0 ? 'success' : 'danger') },
        }" :chart="{
          labels: maxGraphLabels,
          datasets: {
            label: 'NAV',
            data: maxGraphDataSets,
          },
        }" :key="navCardGraphLabels[0]" />
      </div>
      <div class="col-xxl-2  col-xl-3 col-md-6   mt-4 mt-xxl-auto">
        <mini-statistics-card class=" mb-4" :class="riskColor" :title="{
          text: 'Riskometer',
          color: 'opacity-7 text-white',
        }" :value="{
          text: risk,
          color: 'text-white',
          apend: ' ',
          prepend: ' ',
        }" :icon="{
          component: riskClass,
          background: 'bg-white',
        }" direction-reverse />
        <mini-statistics-card :class="[(currentCagr >= 0 ? 'bg-gradient-success' : 'bg-gradient-danger')]" :title="{
          text: cagrLabel,
          color: 'opacity-7 text-white',
        }" :value="{
          text: currentCagr,
          apend: [(typeof currentCagr === 'number' ? ' %' : '')],
          prepend: ' ',
          color: 'text-white',
        }" :icon="{
          component: 'text-dark fa fa-chart-line',
          background: 'bg-white',
        }" direction-reverse />
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6   mt-4 mt-xxl-auto">
        <mini-statistics-card class="bg-gradient-success  mb-4" :title="{
          text: 'AUM',
          color: 'opacity-7 text-white',
        }" :value="{
          text: aum,
          apend: ' Cr',
          prepend: ' ',
          color: 'text-white',
        }" :icon="{
          component: 'text-dark fa fa-landmark',
          background: 'bg-white',
        }" direction-reverse />
        <mini-statistics-card class="bg-gradient-success " :title="{
          text: 'Min Investment',
          color: 'opacity-7 text-white',
        }" :value="{
          text: minimumInvestment,
          prepend: [(typeof minimumInvestment === 'String' ? ' ' : '₹')],
          apend: ' ',
          color: 'text-white',
        }" :icon="{
          component: 'text-dark fa fa-coins',
          background: 'bg-white',
        }" direction-reverse />
      </div>
    </div>
    <div class="row mt-4 align-items-center">
      <div class="col-xl-6 ">
        <div class="card">
          <div class="p-3 pb-0 card-header d-flex">
            <h6 class="my-auto">NAV History</h6>
            <div v-if="showNav" class="nav-wrapper position-relative ms-auto  col-lg-7 col-8 ">
              <ul class="p-1 nav nav-pills nav-fill" role="tablist">
                <li class="nav-item position-relative">
                  <a class="px-0 py-1 mb-0 nav-link active position-relative" data-bs-toggle="tab" href="#cam1"
                    role="tab" aria-controls="cam1" aria-selected="true" @click="switchGraphs('3M')">
                    3 M
                  </a>
                </li>
                <li class="nav-item position-relative">
                  <a class="px-0 py-1 mb-0 nav-link position-relative" data-bs-toggle="tab" href="#cam2" role="tab"
                    aria-controls="cam2" aria-selected="false" @click="switchGraphs('6M')">
                    6 M
                  </a>
                </li>
                <li class="nav-item position-relative">
                  <a class="px-0 py-1 mb-0 nav-link position-relative" data-bs-toggle="tab" href="#cam3" role="tab"
                    aria-controls="cam3" aria-selected="false" @click="switchGraphs('1Y')">
                    1Y
                  </a>
                </li>
                <li class="nav-item position-relative">
                  <a class="px-0 py-1 mb-0 nav-link position-relative" data-bs-toggle="tab" href="#cam3" role="tab"
                    aria-controls="cam3" aria-selected="false" @click="switchGraphs('2Y')">
                    2Y
                  </a>
                </li>
                <li class="nav-item position-relative">
                  <a class="px-0 py-1 mb-0 nav-link  position-relative" data-bs-toggle="tab" href="#cam3" role="tab"
                    aria-controls="cam3" aria-selected="false" @click="switchGraphs('3Y')">
                    3Y
                  </a>
                </li>
                <li class="nav-item position-relative">
                  <a class="px-0 py-1 mb-0 nav-link position-relative" data-bs-toggle="tab" href="#cam3" role="tab"
                    aria-controls="cam3" aria-selected="false" @click="switchGraphs('MAX')">
                    MAX
                  </a>
                </li>
              </ul>
            </div>
            <div v-else class="position-relative ms-auto  col-lg-7 col-8 " style="height:41px;"></div>
          </div>
          <default-line-chart title="Nav History" id="graph" :chart="{
            labels: curentGraphLabels,
            datasets: [
              {
                label: 'NAV',
                data: curentGraphDataSets,
              }
            ]
          }" :key="curentGraphDataSets" />
        </div>
      </div>
      <div class="col-xl-6 mt-4 mt-xl-auto">
        <sector-doughnut-chart :chartLabel="chartLabels" :chartData="chartData" :numOfElem="numChartElem"
          :total="aum" />
      </div>
    </div>
    <footer-card :subtitle="fundManager" :img="amcImg" :schemeDocumentUrl="schemeDocumentUrl" />
    <div class="row mt-4">
      <div class="col-lg-12">
        <page-card :pages="tblData" />
      </div>
    </div>

  </div>
</template>

<script>
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import NavCard from "./components/NavCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import SectorDoughnutChart from "./components/SectorDoughnutChart.vue"
import DetailCard from "./components/DetailCard.vue";
import DefaultLineChart from "./components/DefaultLineChart.vue";
import PageCard from "./components/PagesCard.vue";
import FooterCard from "./components/FooterCard.vue";
import moment from "moment";
import {
  faRocket,
  faScrewdriverWrench,
  faCube,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "Widgets",
  components: {
    MiniStatisticsCard,
    NavCard,
    DetailCard,
    SectorDoughnutChart,
    DefaultLineChart,
    PageCard,
    FooterCard,
  },
  data() {
    return {
      faRocket,
      faScrewdriverWrench,
      faCube,
      isin: "",
      navValue: "",
      tblData: [],
      aum: 0,
      risk: "Not Available",
      riskColor: "bg-success",
      riskClass: 'risk-high',
      chartData: [],
      chartLabels: [],
      sample: "",
      fundManager: "",
      description: "",
      category: "",
      numChartElem: "",
      schemeName: "",
      minimumInvestment: "Not Available",
      yearlyCagr: null,
      doubleYearlyCagr: null,
      tripleYearlyCagr: null,
      maxCagr: null,
      currentCagr: null,
      cagrLabel: "cagr",
      amcImg: "",
      navChange: 0,
      uniqueNumber: "",
      navCardGraphLabels: [],
      navCardGraphDataSets: [],
      curentGraphLabels: [],
      curentGraphDataSets: [],
      quarterGraphLabels: [],
      quarterGraphDataSets: [],
      halflyGraphLabels: [],
      halflyGraphDataSets: [],
      yearlyGraphLabels: [],
      yearlyGraphDataSets: [],
      doubleYearGraphLabels: [],
      doubleYearGraphDataSets: [],
      tripleYearGraphLabels: [],
      tripleYearGraphDataSets: [],
      maxGraphLabels: [],
      maxGraphDataSets: [],
      showNav: true,
      schemeDocumentUrl: "",
      navHisPeriod: 0,
      schemeCode: "",
    }
  },
  mounted() {
    setNavPills();
    this.uniqueNumber = this.$route.params.isin;
    this.getSchemeDetails();
    this.$store.state.showNavbar = true;
  },
  methods:
  {
    stringToTime(dateString) {
      var d1 = dateString.split("/")
      return new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
    },
    checkTimeRange(numDays, checkedDate) {
      let selectedDate = moment(checkedDate);
      let currentDate = moment();
      let expectedDate = currentDate.subtract(numDays, 'days');
      return selectedDate.diff(expectedDate, "days") > 0;
    },
    getCagr(t, c, p) {
      return Math.round((((((c / p) ** (1 / t)) - 1) * 100) * 100)) / 100;
    },

    trimCountToFifty(numDays) {
      return Math.round(numDays / 50);
    },

    otherToLast(array, index) {
      var last = array[index];
      if (last) {
        if (index !== -1) {
          array.splice(index, 1);
        }
        array.push(last);
      }

      return array;
    },
    getNavHistory() {
      var self = this;
      self.$store
        .dispatch("schemeStore/getNavHistory", self.isin, { root: true })
        .then(function (response) {
          var date = new Date();
          var graph = response.server.data;
          let monthlyIncrement = 0,
            quarterIncrement = 0,
            halfIncrement = 0,
            YearlyIncrement = 0,
            doubleYearIncrement = 0, tipleYearIncrement = 0, maxIncrement = 0;
          const date1 = new Date(graph[0].date);
          const diffTime = Math.abs(date - date1);
          var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          let change = (graph[graph.length - 1].value - graph[graph.length - 2].value);



          var initDate = moment(graph[0].date);
          self.navHisPeriod = moment().diff(initDate, 'days');

          self.navChange = Math.round((change / graph[graph.length - 2].value) * 100 * 100) / 100;
          for (let i = 0; i < graph.length; i++) {
            if (self.checkTimeRange(90, graph[i].date) && ((i % self.trimCountToFifty(90) == 0) || (i == graph.length - 1))) {
              self.quarterGraphLabels[quarterIncrement] = graph[i].date;
              self.quarterGraphDataSets[quarterIncrement] = graph[i].value;
              quarterIncrement++;
            }
            if (self.checkTimeRange(180, graph[i].date) && ((i % self.trimCountToFifty(180) == 0) || (i == graph.length - 1))) {
              self.halflyGraphLabels[halfIncrement] = graph[i].date;
              self.halflyGraphDataSets[halfIncrement] = graph[i].value;
              halfIncrement++;
            }
            if (self.checkTimeRange(30, graph[i].date)) {
              self.navCardGraphLabels[monthlyIncrement] = graph[i].date;
              self.navCardGraphDataSets[monthlyIncrement] = graph[i].value;
              monthlyIncrement++;
            }
            if (self.checkTimeRange(365, graph[i].date) && ((i % self.trimCountToFifty(365) == 0) || (i == graph.length - 1))) {
              self.yearlyGraphLabels[YearlyIncrement] = graph[i].date;
              self.yearlyGraphDataSets[YearlyIncrement] = graph[i].value;
              YearlyIncrement++;
            }
            if (self.checkTimeRange(730, graph[i].date) && ((i % self.trimCountToFifty(730) == 0) || (i == graph.length - 1))) {
              self.doubleYearGraphLabels[doubleYearIncrement] = graph[i].date;
              self.doubleYearGraphDataSets[doubleYearIncrement] = graph[i].value;
              doubleYearIncrement++;
            }
            if (self.checkTimeRange(1095, graph[i].date) && ((i % self.trimCountToFifty(1095) == 0) || (i == graph.length - 1))) {
              self.tripleYearGraphLabels[tipleYearIncrement] = graph[i].date;
              self.tripleYearGraphDataSets[tipleYearIncrement] = graph[i].value;
              tipleYearIncrement++;
            }
            if (self.checkTimeRange(diffDays - 1, graph[i].date) && ((i % self.trimCountToFifty(diffDays - 1) == 0) || (i == graph.length - 1))) {
              self.maxGraphLabels[maxIncrement] = graph[i].date;
              self.maxGraphDataSets[maxIncrement] = graph[i].value;
              maxIncrement++;
            }
          }
          self.currentCagr = self.getCagr(1, self.navValue, self.yearlyGraphDataSets[0]);
          self.switchGraphs('3M');
          self.showNav = self.curentGraphLabels.length > 0 && self.curentGraphDataSets.length > 0 ? true : false;
        }).catch(function (error) {
          console.log(error);
        });
    },

    switchGraphs(period) {
      switch (period) {
        case "3M":

          this.curentGraphDataSets = this.quarterGraphDataSets;
          this.curentGraphLabels = this.quarterGraphLabels;
          this.curentGraphLabels.push(this.lastModifiedDate);
          this.curentGraphDataSets.push(this.navValue);
          if ((this.navHisPeriod / 365) >= 1) {
            this.currentCagr = this.yearlyCagr == null ? this.getCagr(1, this.navValue, this.yearlyGraphDataSets[0]) : this.yearlyCagr;
          }
          else {
            this.currentCagr = "- -";
          }
          this.cagrLabel = "CAGR (1 Year)";
          break;

        case "6M":
          this.curentGraphDataSets = this.halflyGraphDataSets;
          this.curentGraphLabels = this.halflyGraphLabels;
          this.curentGraphLabels.push(this.lastModifiedDate);
          this.curentGraphDataSets.push(this.navValue);

          if ((this.navHisPeriod / 365) >= 1) {
            this.currentCagr = this.yearlyCagr == null ? this.getCagr(1, this.navValue, this.yearlyGraphDataSets[0]) : this.yearlyCagr;
          }
          else {
            this.currentCagr = "- -";
          }
          this.cagrLabel = "CAGR (1 Year)";
          break;

        case "1Y":
          this.curentGraphDataSets = this.yearlyGraphDataSets;
          this.curentGraphLabels = this.yearlyGraphLabels;
          this.curentGraphLabels.push(this.lastModifiedDate);
          this.curentGraphDataSets.push(this.navValue);
          if ((this.navHisPeriod / 365) >= 1) {
            this.currentCagr = this.yearlyCagr == null ? this.getCagr(1, this.navValue, this.yearlyGraphDataSets[0]) : this.yearlyCagr;
          }
          else {
            this.currentCagr = "- -";
          }
          this.cagrLabel = "CAGR (1 Year)";
          break;

        case "2Y":
          this.curentGraphDataSets = this.doubleYearGraphDataSets;
          this.curentGraphLabels = this.doubleYearGraphLabels;
          this.curentGraphLabels.push(this.lastModifiedDate);
          this.curentGraphDataSets.push(this.navValue);
          if ((this.navHisPeriod / 365) >= 2) {
            this.currentCagr = this.doubleYearlyCagr == null ? this.getCagr(2, this.navValue, this.doubleYearGraphDataSets[0]) : this.doubleYearlyCagr;
          }
          else {
            this.currentCagr = "- -";
          }
          this.cagrLabel = "CAGR (2 Year)";
          break;

        case "3Y":
          this.curentGraphDataSets = this.tripleYearGraphDataSets;
          this.curentGraphLabels = this.tripleYearGraphLabels;
          this.curentGraphLabels.push(this.lastModifiedDate);
          this.curentGraphDataSets.push(this.navValue);
          if ((this.navHisPeriod / 365) >= 3) {
            this.currentCagr = this.tripleYearlyCagr == null ? this.getCagr(3, this.navValue, this.tripleYearGraphDataSets[0]) : this.tripleYearlyCagr;
          }
          else {
            this.currentCagr = "- -";
          }
          this.cagrLabel = "CAGR (3 Year)";
          break;

        case "MAX":

          this.cagrLabel = "CAGR (MAX)";
          this.curentGraphDataSets = this.maxGraphDataSets;
          this.curentGraphLabels = this.maxGraphLabels;
          var years = (this.navHisPeriod / 365);
          if ((this.navHisPeriod / 365) >= 1) {
            this.currentCagr = this.maxCagr == null ? this.getCagr(years, this.navValue, this.maxGraphDataSets[0]) : this.maxCagr;
          }
          else {
            this.currentCagr = "- -";
          }
          this.curentGraphLabels.push(this.lastModifiedDate);
          this.maxGraphDataSets.push(this.navValue);
          this.maxGraphDataSets[this.maxGraphDataSets.length] = this.navValue;
          this.curentGraphDataSets = this.maxGraphDataSets;
          break;
        default:
          break;
      }
    },

    getNavvalue() {
      var self = this;
      self.$store
        .dispatch("schemeStore/getNav", self.isin, { root: true })
        .then(function (response) {
          self.navValue = response.server.data.navValue;
          self.lastModifiedDate = moment(response.server.data.lastModifiedDate.toString()).format('DD-MMM-yyyy');
          self.getNavHistory();
        }).catch(function (error) {
          self.getNavHistory();
          console.log(error);
          self.showNav = false;
        });
    },
    getSchemeDetails() {
      var self = this;
      self.$store
        .dispatch("schemeStore/getSchemeDetails", self.uniqueNumber, { root: true })
        .then(function (response) {
          var data = response.server.data.schemeDetails.portfolioBeanList;
          self.risk = response.server.data.schemeDetails.riskometer;
          self.risk = !self.risk || ["not applicable", "not available"].includes(self.risk.toLowerCase()) ? "Not Available" : response.server.data.schemeDetails.riskometerLaunch || self.risk;
          self.riskClass = self.getRiskClass(self.risk);
          self.fundManager = response.server.data.schemeDetails.fundManager;
          self.description = response.server.data.schemeDetails.objective;
          self.schemeDocumentUrl = response.server.data.amcDetails.amcDocumentUrl;
          self.category = response.server.data.schemeDetails.category;
          self.minimumInvestment = response.server.data.minimumPurchaseAmount.toFixed(2);
          self.schemeName = response.server.data.schemeName
          self.schemeCode = response.server.data.schemeCode
          self.amcImg = response.server.data.amcDetails.amcImgUrl
          self.isin = response.server.data.schemeDetails.isin;

          let chartData1 = {};
          if (data) {
            for (let item of data) {
              let obj = {
                company: item.instrumentName,
                allocation: item.allocation.toFixed(2),
                investment: item.investment.toFixed(2) + " " + item.denomination,
                sector: item.sector,
                assetclass: item.assetClass,
                rating: item.rating,
              }
              if ((item.allocation >= 0) && (item.investment >= 0)) {
                self.tblData.push(obj);
                self.aum = Math.round(((item.investment + self.aum) * 100)) / 100
              }
              if (!item.sector) {
                item.sector = 'Other';
              }
              if (Object.keys(chartData1).includes(item.sector)) {
                chartData1[item.sector] = chartData1[item.sector] + item.allocation;
              } else {
                chartData1[item.sector] = item.allocation;
              }

            }
          }
          let chartDataArr = []
          let k = 0;
          for (let i in chartData1) {
            chartDataArr[k] = { "sector": i, "value": chartData1[i] }
            k++;
          }
          let sectorData = chartDataArr.sort((a, b) => b.value - a.value);
          if (sectorData.length >= 4) {
            let demoChartDataArray = sectorData.slice(0, 4);
            let remainChartData = sectorData.slice(4);
            if (demoChartDataArray.find(element => element.sector.toLowerCase() == 'other')) {
              demoChartDataArray = sectorData.slice(0, 5);
              remainChartData = sectorData.slice(5);
            }
            let sum = remainChartData.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            let otherData = demoChartDataArray.find(element => element.sector.toLowerCase() == 'other');
            if (otherData) {
              otherData.value = otherData.value + sum;
            } else if (demoChartDataArray.length == 4) {
              demoChartDataArray[4] = { "sector": "Other", "value": sum };
            }
            let demoChartData = [], demoChartLabels = [];
            demoChartDataArray.forEach(val => {
              demoChartData.push(val.value.toFixed(2));
              demoChartLabels.push(val.sector)
            })
            let otherIndex = demoChartLabels.indexOf("Other");
            self.chartLabels = self.otherToLast(demoChartLabels, otherIndex)
            self.chartData = self.otherToLast(demoChartData, otherIndex);
          }
          else {
            let demoChartData = [], demoChartLabels = [];
            sectorData.forEach(val => {
              demoChartData.push(val.value.toFixed(2));
              demoChartLabels.push(val.sector)
            })
            let otherIndex = demoChartLabels.indexOf("Other");
            self.chartLabels = self.otherToLast(demoChartLabels, otherIndex)
            self.chartData = self.otherToLast(demoChartData, otherIndex);
          }
          self.numChartElem = self.chartData.length;
          self.getNavvalue();
        }).catch(function (error) {
          self.showNav = false;
          self.getNavvalue();
          console.log(error);
        });
    },
    getRiskClass(risk) {
      if (!risk) {
        this.riskColor = "bg-gradient-success";
        return '';
      }
      const lowerRisk = risk.toLowerCase();
      if (lowerRisk.includes("high")) {
        this.riskColor = "bg-gradient-danger";
      } else if (lowerRisk.includes("low")) {
        this.riskColor = "bg-gradient-success";
      } else {
        this.riskColor = "bg-gradient-warning";
      }
      if (lowerRisk.includes('low to moderate')) return 'risk-moderate-low risk-style';
      if (lowerRisk.includes('very high')) return 'risk-very-high risk-style';
      if (lowerRisk.includes('moderately high')) return 'risk-moderate-high risk-style';
      if (lowerRisk.includes('moderate')) return 'risk-moderate risk-style';
      if (lowerRisk.includes('high')) return 'risk-high risk-style';
      if (lowerRisk.includes('low')) return 'risk-low risk-style';
      return 'risk-not-applicable risk-style';
    }
  }
};
</script>
