<template>
  <main class="mt-0 main-content">
    <div class="page-header align-items-start min-vh-100 border-radius-lg">
      <span class="mask opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-4 d-lg-flex d-none my-auto pe-0 mx-3 postion-fixed top-0 end-0 text-center justify-content-center flex-column">
            <img class="position-relative z-index-2" src="@/assets/img/signup.png" />
          </div>
          <div class="ms-xl-7 col-xl-5 col-lg-5 col-md-9 pt-6 mb-1">
            <div class="card border shadow-lg z-index-0">
              <div class="row ms-2 me-5 me-sm-6 ms-sm-4 pe-xl-4 px-sm-4">
                <h2 class="text-center mt-2 mb-4 ms-3">Sign up</h2>
                <span class="mb-3 ms-4 ms-xl-4 text-buccaneer text-center px-0">Join OneS with your favourite
                  account</span>
              </div>
              <div class="card-body mx-xl-5 pe-3 mx-2 mx-md-5 pt-0 mx-sm-5">
                <Form role="form" @submit="onSubmit">
                  <div class="mb-5 mb-sm-4">
                    <label class="ones-label-size">Email</label>
                    <soft-input v-model="email" id="email" rules="required:true|email" type="email" isRequired="true"
                      placeholder="Email" name="email" />
                  </div>
                  <div class="mb-5 mb-sm-4">
                    <label class="ones-label-size">Phone Number</label>
                    <soft-input id="PhoneNumber" type="text" rules="required:true|phone" placeholder="9847098762"
                      v-model="phoneNumber" name="Phone Number" maxlength="10" />
                  </div>
                  <div class="mb-5 mb-sm-4">
                    <label class="ones-label-size">Password</label>
                    <soft-input id="password" type="password" placeholder="Create a strong password" name="Password"
                      v-model="password" rules="required:true|password" />
                  </div>
                  <div class="me-2 mt-4 mb-5 mb-sm-2">
                    <soft-checkbox id="flexCheckDefault" rules="is_not:on" name="terms" checked="false" v-model="check">
                      <template v-slot:label>
                        <label class="text-buccaneer font-weight-light">
                          By continuing, I agree to the
                          <a href="#" data-bs-toggle="modal" data-bs-target="#modal-default"
                            @click="showContent('terms')" class="text-info font-weight-bolder me-1"><u> Terms of Use
                            </u></a><b>&</b><a href="#" data-bs-toggle="modal" data-bs-target="#modal-default"
                            @click="showContent('privacy')" class="text-info font-weight-bolder ms-1"><u>Privacy
                              Policy</u>.</a></label>
                      </template>
                    </soft-checkbox>
                  </div>
                  <div class="text-center"> <small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
                  </div>
                  <div class="text-center mt-2">
                    <soft-button color="primary" type="submit" class="my-4 w-100 mb-2"> Sign up</soft-button>
                  </div>
                </Form>
                <p class="text-sm text-center mt-3 mb-0">
                  Already have an account?
                  <router-link :to="{ name: 'Sign In' }" class="text-info font-weight-bolder">
                    Sign in
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-labelledby="modal-default"
        aria-hidden="true">
        <div class="modal-dialog w-100 h-100 modal-dialog-centered" role="document">
          <div class="modal-content" style="max-height: 70%; max-width: 90%">
            <div class="text-center my-3">
              <h6 class="modal-title text-center" id="modal-title-default">
                {{ modalTitle }}
              </h6>
            </div>
            <div class="overflow-auto container" style="max-width: 100%; max-height: 100%">
              <section v-if="modalContent === 'terms'">
                <p class="text-justify">
                  Welcome to OneS. By accessing our website, services, and products, you agree to comply with and be
                  bound
                  by the following terms and conditions of use, which together with our privacy policy govern OneS's
                  relationship with you in relation to this website and its services. Your privacy is critically
                  important to us. At OneS, we have a few fundamental principles:
                </p>
                <ul>
                  <li>We don’t ask you for personal information unless we truly need it.</li>
                  <li>We don’t share your personal information with anyone except to comply with the law, develop our
                    products,
                    or protect our rights.</li>
                  <li>We store personal information on our servers unless required for the ongoing operation of one of
                    our
                    services.</li>
                </ul>
                <p class="text-justify">
                  In our privacy policy, you can find detailed information about how we collect, use, communicate,
                  disclose,
                  and otherwise manage your personal data. We will only retain personal information as long as necessary
                  for
                  the fulfillment of those purposes. We are committed to conducting our business in accordance with
                  these
                  principles in order to ensure that the confidentiality of personal information is protected and
                  maintained.
                </p>
                <p class="text-justify">
                  Remember to consult with a legal expert to ensure that your Terms of Use and Privacy Policy are
                  compliant
                  with all relevant laws and regulations, especially if your service operates in multiple jurisdictions
                  or
                  handles sensitive personal data.
                </p>
              </section>
              <section v-if="modalContent === 'privacy'">
                <p class="text-justify">
                  Welcome to OneS's Privacy Policy. Your privacy is critically important to us. At OneS, we have a few
                  fundamental principles:
                </p>
                <ul>
                  <li>We don’t ask you for personal information unless we truly need it.</li>
                  <li>We don’t share your personal information with anyone except to comply with the law, develop our
                    products,or protect our rights.</li>
                  <li>We store personal information on our servers unless required for the ongoing operation of one of
                    our
                    services.</li>
                </ul>
                <p class="text-justify">
                  In our privacy policy, you can find detailed information about how we collect, use, communicate,
                  disclose,
                  and otherwise manage your personal data. We will only retain personal information as long as necessary
                  for
                  the fulfillment of those purposes. We are committed to conducting our business in accordance with
                  these
                  principles in order to ensure that the confidentiality of personal information is protected and
                  maintained.
                </p>
                <p class="text-justify">
                  Remember to consult with a legal expert to ensure that your Privacy Policy is compliant with all
                  relevant
                  laws and regulations, especially if your service operates in multiple jurisdictions or handles
                  sensitive
                  personal data.
                </p>
              </section>
            </div>
            <div class="text-end">
              <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftCheckbox from "@/views/common/components/SoftCheckbox.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
export default {
  name: "Signup",
  components: {
    SoftInput,
    SoftCheckbox,
    SoftButton,
    Form,
  },
  data() {
    return {
      email: "",
      phoneNumber: "",
      password: "",
      check: "on",
      isSuccess: true,
      error: "",
      modalContent: "",
      modalTitle: "",
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    onSubmit() {
      let lowercaseEmail = this.email.toLowerCase();
      sessionStorage.setItem("signupEmail", lowercaseEmail);
      sessionStorage.setItem("phoneNumber", this.phoneNumber);
      let data = {
        "email": lowercaseEmail,
        "phoneNumber": this.phoneNumber,
        "password": this.password,
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/signup", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          self.$router.push({ name: 'Mobile Verification' });
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });

    },
    showContent(type) {
      if (type === 'terms') {
        this.modalTitle = "Terms of Use";
        this.modalContent = "terms";
      } else if (type === 'privacy') {
        this.modalTitle = "Privacy Policy";
        this.modalContent = "privacy";
      }
    }
  },
};
</script>
