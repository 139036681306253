

import "./assets/js/nav-pills.js";
import "./assets/scss/soft-ui-dashboard.scss";
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  // eslint-disable-next-line prettier/prettier
  install() {}
};
