<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-column flex-md-row">
      <div class="col p-0">
    <div class="mt-6 page-header min-height-160 border-radius-xl portfolio-headerimage">
    </div>
    <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5">
      <div class="row w-100 px-0">
        <div class="col-12">
          <div class="card-header p-0">
            <h4 class="mb-2 ms-3">Your Portfolio <br><span class="text-sm ms-2">As of {{ currentDate }}</span></h4>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <outlined-counter-card :duration="500" prefix="₹" :count="totalInvested" title="Invested Value" />
              <outlined-counter-card :duration="500" prefix="₹" :count="totalCurrent" title="Current Value"
                percentage="%" />
              <outlined-counter-card class="mt-4 mt-lg-0" :duration="500" prefix="₹" :count="totalPNL"
                title="Total P & L" />
              <outlined-counter-card :duration="500" class="mt-4 mt-lg-0" :count="totalReturn" title="Returns"
                suffix="%" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="schemes && schemes.length > 0" class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5">
      <div id="All" class="container-fluid mt-6 px-0">
        <Table />
      </div>
    </div>
    <div class="card card-body blur shadow mx-3 mx-md-4 mt-6 mb-5">
      <LandingpageCards />
    </div>
  </div>
  </div>
  </div>
  <Rmodal v-for="fundId in portfolioScheme" :key="fundId" :fundId="fundId"></Rmodal>
</template>

<script>
import OutlinedCounterCard from "../common/components/OutlinedCounterCard.vue";
import Table from "../../views/portfolio/Table.vue";
import { mapState } from "vuex";
import Rmodal from "../../views/portfolio/Redemption.vue";
import LandingpageCards from "../common/components/LandingpageCards.vue";
export default {
  name: "Portfolio",
  components: {
    OutlinedCounterCard,
    Table,
    Rmodal,
    LandingpageCards,
  },
  data() {
    return {
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
      currentDate: this.getCurrentDate(),
      schemes: [],
      portfolioScheme: "",
    };
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return today.toLocaleDateString(undefined, options);
    },
  },
  mounted() {
    this.$store.state.showFooter = false;
    const schemes = sessionStorage.getItem("schemes");
    if (schemes) {
      this.schemes = JSON.parse(schemes);
      console.log(Object.groupBy(this.schemes, ({ fundId }) => fundId));
      this.portfolioScheme = Object.keys(Object.groupBy(this.schemes, ({ fundId }) => fundId));
    }
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "color",
      "showSidenav",
    ]),
    totalInvested() {
      var value = 0
      if (this.schemes && this.schemes.length > 0) {
        value = (this.schemes.reduce((sum, a) => sum + a.amount, 0)).toFixed(2);

      }
      return value;
    },
    totalCurrent() {
      var value = 0
      if (this.schemes && this.schemes.length > 0) {
        value = (this.schemes.reduce((sum, a) => sum + a.currentValue, 0)).toFixed(2);

      }
      return value;
    },
    totalPNL() {
      return this.totalCurrent - this.totalInvested;
    },
    totalReturn() {
      if (this.totalInvested == 0) {
        return 0;
      } else {
        return (Math.round(((this.totalCurrent - this.totalInvested) / this.totalInvested) * 10000) * .01).toFixed(2);
      }

    }

  },
};
</script>
