import { useLoading } from 'vue3-loading-overlay'

const state = {
  loading:null,
  isSpinnerActive:false
  // Layout Switching Variables - Start 


};
const getters = {
};
const actions = {
};
const mutations = {
  setSpinnerActive(state, isActive) {
    state.isSpinnerActive = isActive;
  },
  updateBusyState(context, data) {
    console.log(context)
    console.log(data)
    let loader = useLoading();
    console.log(loader)
  },
};
export const layoutStore = {
  namespaced: true,
  state,
  actions,
  mutations,getters
};
