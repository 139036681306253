import axios from "axios";

function getHttpDevClient() {
  return axios.create({
    baseURL: "https://dev.mf.ones.money/api",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+ sessionStorage.getItem('accessToken'),
    },
  });
}

function getHttpProdClient() {
  return axios.create({
    baseURL: "https://mf.ones.money/api",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+  sessionStorage.getItem('accessToken'),
    },
  });
}

function getHttpDevFileClient() {
  return axios.create({
    baseURL: "https://dev.mf.ones.money/api",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "blob",
  });
}

function getHttpProdFileClient() {
  return axios.create({
    baseURL: "https://mf.ones.money/api",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "blob",
  });
}


function getHttpClient() {
  return process.env.NODE_ENV === "production" ? getHttpProdClient() : getHttpDevClient();
}
function getHttpFileClient() {
  return process.env.NODE_ENV === "production"? getHttpProdFileClient(): getHttpDevFileClient();
}

function getInfo(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
    getHttpClient( requestUrl)
      .get(requestUrl)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch(function (error) {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}

function getFile(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
    getHttpFileClient( requestUrl)
      .get(requestUrl)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch(function (error) {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}

function postFile(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
    getHttpFileClient( requestUrl)
      .post(requestUrl, requestData)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch((error) => {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}

function postInfo(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
      getHttpClient( requestUrl)
        .post(requestUrl, requestData)
        .then((response) => {
          onSuccess(
            response,
            requestData,
            requestParameter,
            context,
            successHandlerName,
            resolve
          );
        })
        .catch((error) => {
          console.log(error.message);
          onError(
            error,
            requestData,
            requestParameter,
            context,
            errorHandlerName,
            reject
          );
        });
  });
}

function putInfo(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
    getHttpClient( requestUrl)
      .put(requestUrl, requestData)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch(function (error) {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}

function patchInfo(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
    getHttpClient( requestUrl)
      .patch(requestUrl, requestData)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch(function (error) {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}

function deleteInfo(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    setSystemAsBusy(context);
    getHttpClient()
      .delete(requestUrl)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch(function (error) {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}

function onSuccess(
  response,
  requestData,
  requestParameter,
  context,
  commitMethodName,
  resolve
) {
  let data = {
    server: response,
    requestData: requestData,
    parameter: requestParameter,
  };
  if (context) {
    if (commitMethodName) context.commit(commitMethodName, data);
  }
  setSystemAsFree(context);
  resolve(data);
}

function onError(
  errorResponse,
  requestData,
  requestParameter,
  context,
  commitMethodName,
  reject
) {
  setSystemAsFree(context);
  let data = {
    server: errorResponse,
    requestData: requestData,
    parameter: requestParameter,
  };
  if (
    errorResponse &&
    errorResponse.response &&
    errorResponse.response.status === 401 &&
    context
  ) {
    console.log("Unauthorized access - Error 401");
  }
  if (commitMethodName) {
    context.commit(commitMethodName, data);
  }
  reject(data);
}

function setSystemAsBusy(context) {
  if (context) 
    context.commit("layoutStore/setSpinnerActive", true, { root: true });
}
function setSystemAsFree(context) {
  if (context) 
    context.commit("layoutStore/setSpinnerActive", false, { root: true });
}

export default {
  getInfo,
  getFile,
  postInfo,
  putInfo,
  patchInfo,
  deleteInfo,
  postFile,
};
