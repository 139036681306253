<template>
  <div class="form-group">
    <div :class="['input-group', hasIcon(icon) || type === 'password' ? 'input-group' : '']">
      <Field
        :id="id"
        :value="value"
        :type="currentInputType"
        class="form-control border border-light-mauve"
        autocomplete="off"
        :class="getClasses(size, success, error)"
        :name="name"
        :placeholder="placeholder"
        :isRequired="isRequired"
        :rules="rules"
        v-bind="$attrs"
        :maxlength="maxlength"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      
      <span v-if="type === 'password'" class="input-group-text border border-light-mauve" @click="togglePasswordVisibility">
        <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
      </span>
      <span v-else-if="icon && iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
    <div class="error-height">
    <ErrorMessage
        as="small"
        class="mt-1 text-danger text-wrap font-weight-light text-xs"
        :name="name"
      />
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "SoftInput",
  components: {
    Field,
    ErrorMessage,
  },
  prop: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      showPassword: false,
    };
  },
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Type here...",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array, Object],
      default: "",
    },
    maxlength:{
      type: Number,
      default:30,
    },
    minlength:{
      type: Number,
      default:4,
    }
  },
  computed: {
    currentInputType() {
      return this.type === 'password' ? (this.showPassword ? 'text' : 'password') : this.type;
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }
      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
};
</script>