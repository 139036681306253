<template>
  <div class="py-7 container-fluid">
    <div class="row">
      <div
        class="col-4 ms-auto d-lg-flex d-none h-100 my-6 pe-0 position-relative top-0 end-0 text-center justify-content-center flex-column">
        <img class="w-100 position-relative z-index-2" src="@/assets/img/Nominee.png" />
      </div>
      <div class="mx-auto col-xl-5 col-lg-7 col-md-9">
        <div class="card border shadow-lg z-index-0">
          <div class="row ms-4 me-6 pe-xl-4 px-sm-4">
            <h4 class="text-center mt-3 mb-3 ms-3">Nominee Details</h4>
          </div>
          <div class="card-body mx-2 px-3 mx-sm-4">
            <Form role="form" @submit="onSubmit">
              <div v-for="(nominee, index) in nominees" :key="nominee.id">
                <i v-if="nominees.length > 1 && (nominees.length === 3 ? index >= 0 : true)"
                  class="fa fa-trash icon-background-color d-flex justify-content-end"
                  @click="deleteNominee(index)"></i>
                <div class="row mt-1">
                  <div class="col-12 col-sm-6 mt-sm-2">
                    <label for="Name" class="form-label ones-label-size ms-2">Nominee Name</label>
                    <soft-input :id="'Name' + index" v-model="nominees[index].nomineeName" type="text"
                      rules="required:true" placeholder="Enter Name" :name="'Nominee Name' + index"
                      :label="'Nominee Name'" />
                  </div>
                  <div class="col-12 col-sm-6 col-lg-6 mt-3 mt-sm-2 mb-3 mb-sm-3">
                    <label for="relation" class="form-label ones-label-size ms-2">Nominee Relation</label>
                    <soft-select :id="'relation' + index" v-model="nominees[index].type" :options="relationType"
                      class="soft-select-margin" rules="required:true" :name="'Nominee Relation' + index"
                      :label="'Nominee Relation'" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 mt-sm-2 mb-3 mb-sm-3">
                    <label for="DOB" class="form-label ones-label-size ms-2">Date Of Birth</label>
                    <datepicker :full-month-name="true" :id="'DOB' + index" v-model="nominees[index].dob" class="w-100"
                      input-class="py-2 pe-5 form-control light-mauve" placeholder="DD/MM/YYYY" :typeable="true"
                      :hideInput="false" @input="checkIfMinor(nominee)" :disabled-dates="disabledDates">
                    </datepicker>
                    <div class="text-center"><small class="mt-1 text-danger text-wrap font-weight-light text-xs"
                        v-if="checkIfMinor(nominee)">{{ error }}</small>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 mt-3 mt-sm-2 mb-4 mb-sm-3">
                    <label for="share" class="form-label ones-label-size ms-2">Nominee Share(in %)</label>
                    <soft-input :id="'share' + index" v-model="nominees[index].nomineeShare" type="number"
                      rules="required:true" min="0" max="100" step="0.01" placeholder="Enter Percentage"
                      :name="'Nominee Share' + index" :label="'Nominee Share'" />
                  </div>
                </div>
                <div v-if="nominee.minor">
                  <div class="row">
                    <div class="col-12">
                      <label for="guardianName" class="form-label ones-label-size ms-2">Guardian Name (Required for
                        minors)</label>
                      <soft-input :id="'guardianName' + index" v-model="nominees[index].guardianName" class="form-input"
                        rules="required:true" type="text" placeholder="Enter Guardian Name"
                        :name="'Guardian Name' + index" :label="'Guardian Name'" />
                    </div>
                  </div>
                </div>
                <hr class="text-light-mauve font-weight-light">
              </div>
              <div class="d-flex justify-content-end">
                <div v-if="nominees.length < 3" @click="addNominee">
                  <i class="fa-solid fa-circle-plus icon-background-color"></i>
                  <label class="font-weight-bold cursor-pointer">Add another nominee</label>
                </div>
              </div>
              <div class="text-center mb-5 error-height"><small class="mt-4 text-danger text-xs"
                  v-if="!isverified || !isSuccess">{{ errorMessage }}</small>
              </div>
              <div class="text-center">
                <soft-button color="primary" size="lg" full-width type="submit">proceed
                </soft-button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import Datepicker from 'vuejs3-datepicker';
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftSelect from "@/views/common/components/SoftSelect.vue";
import { mapMutations } from "vuex";
import { Form } from "vee-validate";

export default {
  name: "NomineeDetails",
  components: {
    SoftInput,
    SoftButton,
    SoftSelect,
    Datepicker,
    Form,
  },
  data() {
    return {
      nominees: [{
        id: this.generateUniqueId(),
        nomineeName: "",
        type: "",
        dob: "",
        nomineeShare: "",
        guardianName: "",
        minor: false,
      }],
      isverified: true,
      isInvalid: false,
      downarrow: false,
      relationType: [
        { value: "Select Relation", text: "Select Relation" },
        { value: "FATHER", text: "FATHER" },
        { value: "MOTHER", text: "MOTHER" },
        { value: "BROTHER", text: "BROTHER" },
        { value: "WIFE", text: "WIFE" },
        { value: "HUSBAND", text: "HUSBAND" },
        { value: "SON", text: "SON" },
        { value: "DAUGHTER", text: "DAUGHTER" },
        { value: "SISTER", text: "SISTER" },
        { value: "GRAND_SON", text: "GRAND SON" },
        { value: "GRAND_DAUGHTER", text: "GRAND DAUGHTER" },
        { value: "GRAND_FATHER", text: "GRAND FATHER" },
        { value: "GRAND_MOTHER", text: "GRAND MOTHER" },
        { value: "OTHERS", text: "OTHERS" },
      ],
      error: "",
      errorMessage: "",
      isSuccess: true,
      disabledDates: {
        from: new Date(),
      }
    };
  },
  computed: {
    canAddNominee() {
      return this.nominees.length < 3 && this.nominees.every(nominee => nominee.nomineeName && nominee.type && nominee.dob && nominee.nomineeShare && (!nominee.minor || nominee.guardianName));
    },
    maxNomineeShare() {
      return this.remainingShares > 50 ? 50 : this.remainingShares;
    },
    totalShares() {
      return this.nominees.reduce((total, nominee) => total + parseFloat(nominee.nomineeShare || 0), 0);
    },
    remainingShares() {
      return 100 - this.totalShares;
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  watch: {
    'nominees': {
      handler() {
        if (this.totalShares > 100) {
          this.isverified = false;
          this.errorMessage = "Total shares have exceeded 100%";
        } else {
          this.isverified = true;
          this.errorMessage = "";
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today
    },
    addNominee() {
      if (this.totalShares >= 100) {
        this.errorMessage = "Total shares have already reached 100%. No additional nominees can be added.";
        this.isverified = false;
      } else if (this.canAddNominee) {
        this.nominees.push({
          id: this.generateUniqueId(),
          nomineeName: "",
          type: "",
          dob: "",
          nomineeShare: Math.min(this.remainingShares),
          guardianName: "",
        });
        this.isverified = true;
      } else {
        this.errorMessage = "Please complete all fields for existing nominees before adding a new one.";
        this.isverified = false;
      }
    },
    deleteNominee(nomineeId) {
      this.nominees.splice(nomineeId, 1);
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = ('0' + d.getDate()).slice(-2);
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    },
    checkIfMinor(nominee) {
      const today = new Date();
      const birthDate = new Date(nominee.dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      if (age < 18) {
        this.error = "Guardian's name required for minors.";
        nominee.minor = true;
        return true;
      } else {
        this.error = "";
        nominee.minor = false;
        return false;
      }
    },
    checkForDuplicates() {
      const seen = new Map();
      for (const nominee of this.nominees) {
        const key = `${nominee.nomineeName.toLowerCase()}|${this.formatDate(nominee.dob)}}`;
        if (seen.has(key)) {
          this.errorMessage = "Duplicate entries are not allowed. Please check the nominee details.";
          return true;
        }
        seen.set(key, true);
      }
      return false;
    },
    onSubmit() {
      let nomineeData = this.nominees.map(nominee => ({
        name: nominee.nomineeName,
        percentage: nominee.nomineeShare,
        relationship: nominee.type,
        dob: this.formatDate(nominee.dob),
        guardianName: nominee.minor ? nominee.guardianName : undefined
      }));
      const allDobsFilled = this.nominees.every(nominee => nominee.dob);
      if (this.checkForDuplicates()) {
        this.isverified = false;
        return;
      } else if (!allDobsFilled) {
        this.errorMessage = "Date of birth must be filled out for all nominees.";
        this.isverified = false;
      } else if (this.totalShares !== 100) {
        this.errorMessage = "Please ensure the total shares sum up to 100% before proceeding.";
        this.isverified = false;
      } else {
        let data = {
          "nomineeData": nomineeData,
        };
        var self = this;
        this.$store
          .dispatch("userStore/nomineeDetails", data, { root: true })
          .then(function (response) {
            console.log(response);
            self.isSuccess = true;
            self.$router.push("/bankaccountregister");
          })
          .catch(function (error) {
            self.isSuccess = false;
            self.errorMessage = error.message ? error.message : "An error occurred during the process. Please try again.";
          });
      }
    },
  },
};
</script>