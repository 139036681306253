<template>
  <div class="form-group">
    <Field :id="id" class="form-control" :class="getClasses(size, success, error)" :name="name" :isRequired="isRequired"
      :rules="rules" v-bind="$attrs" v-slot="{ field }">
      <flat-pickr ref="calender" :config="this.config" v-bind="field" class="form-control border border-light-mauve"
        :class="isDisabled ? 'bg-light' : 'bg-white'" :placeholder="placeholder" :disabled="isDisabled" enableTime=true
        dateFormat="Y-m-d H:i"></flat-pickr>
        <div class="error-height">
      <ErrorMessage as="small" class="mt-3 text-danger font-weight-light text-xs" :name="name" />
      </div>
    </Field>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import FlatPickr from "vue-flatpickr-component";
export default {
  name: "SoftCalendar",
  components: {
    Field,
    FlatPickr,
    ErrorMessage,
  },
  data() {
    return {
      config: " ",
    };
  },
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Type here...",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array, Object],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const today = new Date();
    const maxYear = today.getFullYear() - 18;
    const maxMonth = today.getMonth() + 1;
    const maxDay = today.getDate();
    const maxDateFormatted = `${maxDay}-${maxMonth}-${maxYear}`;
    this.config = {
        wrap: true,
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'd-m-Y',
        maxDate: maxDateFormatted,
    };
  },
  methods: {
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;
      sizeValue = size ? `form-control-${size}` : null;
      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }
      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
    hasdisable: (disabled) => (disabled ? "disabled" : null),
  },
};
</script>
