<template>
  <section class="py-6 position-relative">
    <div class="container mt-8 mt-sm-2">
      <div class="row">
        <div class="col-10 mx-auto  border-radius-lg">
          <div class="row py-5">
            <div class="col-xl-4 col-md-6 px-5 mb-5 position-relative">
              <img class="img border-radius-md max-width-300 w-100 position-relative z-index-2 mt-n7"
                src="../assets/img/NFO.png" loading="lazy" alt="card image" />
            </div>
            <div class="col-xl-8 col-md-6 z-index-2 position-relative px-md-3  my-md-auto mt-sm-4">
              <h3 class=" ms-lg-9 ">Explore Latest NFOs</h3>
              <p class="text-center text-justify ms-lg-9 ">
                A new fund offer (NFO) is the first subscription offering for any new fund offered by an investment
                company.Be the first to invest in New Fund Offers
              </p>
              <router-link to="/NFO">
                <span class="text-primary icon-move-right ms-lg-9">View NFO
                  <i class="fas fa-arrow-right text-xs ms-1"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
