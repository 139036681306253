import { defineRule } from "vee-validate";
import * as rules from "@vee-validate/rules";
import moment from "moment";


Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
});

defineRule("pastDate", (value) => {
  let today = moment(new Date());
  let selTime = moment(value, "DD/MM/YYYY");
  let age = today.diff(selTime, 'years');
  if (age >= 18) {
    return true
  }
  return false;
});

defineRule('noMatch', (value, [target], ctx) => {
  if (value !== ctx.form[target]) {
    return true;
  }
  return false;
});

import { configure } from "vee-validate";
configure({
  validateOnInput: true,
});

