var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
import requestCommon from "@/components/web-interface/requestCommon";
const state = {
  loggedIn: false,
  userPayload: {},
  token: "",
  authenticationError: "",
  signupData: {},
  accessToken:"",
  name:"",
  email:"",
  user_signup_status: "",
  refreshToken:"",
};
const actions = {
  setSystemAsBusy(context) {
    if (context)
      context.commit("layoutStore/setSpinnerActive", true, { root: true });
  },
  setSystemAsFree(context) {
    if (context) 
      context.commit("layoutStore/setSpinnerActive", false, { root: true });
  },
  signup(context, data) {
    return new Promise((resolve, reject) => {
      actions.setSystemAsBusy(context);
      var attributeList = [];
      var poolData = {
        UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'name',
        Value: data.email
      })
      var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'email',
        Value: data.email
      })
      var attributePhone = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'phone_number',
        Value: '+91' + data.phoneNumber,
      });
      attributeList.push(attributeName);
      attributeList.push(attributeEmail);
      attributeList.push(attributePhone);
      userPool.signUp(data.email, data.password, attributeList, null, (err, result) => {

        if (err) {
          actions.setSystemAsFree(context);
          context.commit('signupErrorHandler', err);
          reject(err);
        } else {
          actions.setSystemAsFree(context);
          context.commit('signupSuccessHandler', result);
          resolve();
        }
      })
    })
  },
  signIn(context, data) {
    return new Promise((resolve, reject) => {
      actions.setSystemAsBusy(context);
      var authenticationData = {
        Username: data.email,
        Password: data.password
      }
      var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
      var pool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID, ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID })
      var userData = { Username: data.email, Pool: pool }
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          actions.setSystemAsFree(context);
          sessionStorage.clear();
          context.commit('signInSuccessHandler', result);
          resolve();
        },
        onFailure: (err) => {
          actions.setSystemAsFree(context);
          context.commit('signInErrorHandler', err);
          reject(err);
        },
        mfaRequired: function (codeDeliveryDetails) {
          context.commit('signInErrorHandler', codeDeliveryDetails);
          reject("Mfa required");
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          context.commit('signInErrorHandler', requiredAttributes);
          reject("New password required");
        }
      })
    })
  },
  otpVerification(context, data) {
    return new Promise((resolve, reject) => {
      actions.setSystemAsBusy(context);
      var poolData = {
        UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      var userData = {
        Username: data.email,
        Pool: userPool
      }
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
      cognitoUser.confirmRegistration(data.otp, true, (err, result) => {
        if (err) {
          actions.setSystemAsFree(context);
          context.commit('otpVerificationErrorHandler', err);
          reject(err);
        } else {
          actions.setSystemAsFree(context);
          context.commit('otpVerificationSuccessHandler', result);
          resolve();
        }
      })
    })
  },
  resendConfirmationCode(context, data) {
    return new Promise((resolve, reject) => {
      actions.setSystemAsBusy(context);
      var poolData = {
        UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      var userData = {
        Username: data.email,
        Pool: userPool
      }
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          actions.setSystemAsFree(context);
          context.commit('resendConfirmationCodeErrorHandler', err);
          reject(err);
        } else {
          actions.setSystemAsFree(context);
          context.commit('resendConfirmationCodeSuccessHandler', result);
          resolve();
        }
      })
    })
  },
  getCkyc(context, data) {
    return requestCommon.patchInfo(
      "/us/customers/kyc",
     null,
      data,
      context,
      "getCkycSuccessHandler",
      "getCkycErrorHandler"
    );
  },
  emailVerification(context, data) {
    return requestCommon.putInfo(
      "/us/users",
      null,
      data,
      context,
      "emailverificationSuccessHandler",
      "emailverificationErrorHandler"
    );
  },
  
  resendEmailOtp(context, data) {
    return requestCommon.patchInfo(
      "/us/users/email",
      null,
      data,
      context,
      "resendEmailOtpSuccessHandler",
      "resendEmailOtpErrorHandler"
    );
  },
  logout(context) {
    return new Promise((resolve, reject) => {
      actions.setSystemAsBusy(context);
        var poolData = {
            UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
            ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        };
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        
        var currentUser = userPool.getCurrentUser();
        if (currentUser !== null) {
            currentUser.signOut();
            actions.setSystemAsFree(context);
            sessionStorage.clear();
            localStorage.clear();
            context.commit('logoutSuccessHandler');
            resolve();
        } else {
            context.commit('logoutErrorHandler');
            actions.setSystemAsFree(context);
            reject(new Error("No user is currently logged in."));
        }
    });
},
forgotPassword(context, data) {
  return new Promise((resolve, reject) => {
    actions.setSystemAsBusy(context);
    var pool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID, ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID })
    var userData = { Username: data.email, Pool: pool }
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        actions.setSystemAsFree(context);
        context.commit('forgotPasswordSuccessHandler', result);
        resolve();
      },
      onFailure: (err) => {
        actions.setSystemAsFree(context);
        context.commit('forgotPasswordErrorHandler', err);
        reject(err);
      },
    })
  })
},
newPassword(context, data) {
  return new Promise((resolve, reject) => {
    actions.setSystemAsBusy(context);
    var pool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID, ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID })
    var userData = { Username: data.email, Pool: pool }
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    cognitoUser.confirmPassword(data.otp, data.password, {
      onSuccess: (result) => {
        actions.setSystemAsFree(context);
        context.commit('newPasswordSuccessHandler', result);
        resolve();
      },
      onFailure: (err) => {
        actions.setSystemAsFree(context);
        context.commit('newPasswordErrorHandler', err);
        reject(err);
      },
    })
  })
},
additionalInfo(context, data) {
  return requestCommon.patchInfo(
    "/us/users/detail",
    null,
    data,
    context,
    "additionalinfoSuccessHandler",
    "additionalinfoErrorHandler"
  );
},
pennyDrop(context, data) {
  return requestCommon.patchInfo(
    "/us/users/bankdetails",
    null,
    data,
    context,
    "pennyDropSuccessHandler",
    "pennyDropErrorHandler"
  );
},
refreshAccessToken(context, data) {
  return new Promise((resolve, reject) => {
    var poolData = {
      UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    var userData = {
      Username: data.email,
      Pool: userPool
    }
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    var refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: data.refreshToken});
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) {
        context.commit('refreshAccessTokenErrorHandler', err);
        reject(err);
      } else {
        context.commit('refreshAccessTokenSuccessHandler', session);
        resolve();
      }
    })
  })
},
nomineeDetails(context, data) {
  const payload = data.nomineeData;
  return requestCommon.postInfo(
    "/us/users/nominee",
    null,
    payload,
    context,
    "nomineeDetailsSuccessHandler",
    "nomineeDetailsErrorHandler"
  );
},
};
const mutations = {
  setUserPayload(state, userPayload) {
    state.userPayload = userPayload;
  },
  clearState(state) {
    state.loggedIn = false;
    state.userPayload = {};
    state.token = "";
    state.signupData = {};
    state.accessToken = "";
    state.name = "";
    state.email = "";
    state.user_signup_status = "";
    state.refreshToken = "";
  },
  setLoggedIn(state, value) {
    state.loggedIn = value;
  },
  signupSuccessHandler(state, data) {
    state.signupData = data;
    sessionStorage.setItem("signupData", JSON.stringify(data));
  },
  signupErrorHandler(context, error) {
    console.log("sign up failed" + error);
  },
  signInSuccessHandler(state, data) {
    state.userPayload = data.idToken.payload;
    state.token = data.idToken.jwtToken;
    state.accessToken = data.accessToken.jwtToken;
    state.refreshToken = data.refreshToken.token;
    sessionStorage.setItem("userPayload", JSON.stringify(data.idToken.payload));
    sessionStorage.setItem("token", data.idToken.jwtToken);
    sessionStorage.setItem("accessToken", data.accessToken.jwtToken);
    sessionStorage.setItem("refreshToken",data.refreshToken.token);
    state.authenticationError = ""
  },
  signInErrorHandler(context, error) {
    state.authenticationError = 'Invalid username/password combination'
    console.log(error?.message);
  },
  otpVerificationSuccessHandler() {
    console.log("successfull otp verification");
  },
  otpVerificationErrorHandler(context, error) {
    console.log("otp verification failed" + error);
  },
  resendConfirmationCodeSuccessHandler() {
    console.log("The OTP has been successfully resent.");
  },
  resendConfirmationCodeErrorHandler(context, error) {
    console.log("resendConfirmationCode failed" + error);
  },
  getCkycSuccessHandler(context, data) {
    console.log(data);
  },
  getCkycErrorHandler(context, error) {
    console.log(error);
  },
  emailverificationSuccessHandler() {
    console.log("successfull otp verification");
  },
  emailverificationErrorHandler(context, error) {
    console.log("otp verification failed" + error);
  },
  resendEmailOtpSuccessHandler() {
    console.log("The Email OTP has been successfully resent.");
  },
  resendEmailOtpErrorHandler(context, error) {
    console.log("resendEmailOtp failed" + error);
  },
  logoutSuccessHandler(state) {
    state.loggedIn = false;
  },
  logouterrorHandler() {
    console.log("not clear user data");
  },
  forgotPasswordSuccessHandler(state, data) {
    console.log('forgot password initiated: ' + JSON.stringify(data))
  },
  forgotPasswordErrorHandler(context, error) {
    console.log("forgot Password failed" + error);
  },
  newPasswordSuccessHandler(state, data) {
    console.log('forgot password confirmed: ' + JSON.stringify(data))
  },
  newPasswordErrorHandler(context, code) {
    console.log('forgot password confirm error: ' + code)
  },
  additionalinfoSuccessHandler() {
    console.log("successfull stored data");
  },
  additionalinfoErrorHandler(context, error) {
    console.log("additionalinfo " + error);
  },
 pennyDropSuccessHandler() {
    console.log("successfull stored data");
  },
 pennyDropErrorHandler(context, error) {
    console.log("pennydrop" + error);
  },
  refreshAccessTokenErrorHandler(context, error) {
    console.log(" refreshAccessToken failed" + error);
  },
  refreshAccessTokenSuccessHandler(state, data) {
    state.userPayload = data.idToken.payload;
    state.token = data.idToken.jwtToken;
    state.accessToken = data.accessToken.jwtToken;
    sessionStorage.setItem("userPayload", JSON.stringify(data.idToken.payload));
    sessionStorage.setItem("token", data.idToken.jwtToken);
    sessionStorage.setItem("accessToken", data.accessToken.jwtToken);
    sessionStorage.setItem("refreshToken",data.refreshToken.token);
    state.authenticationError = ""
  },
  nomineeDetailsSuccessHandler() {
    console.log("successfull stored data");
  },
  nomineeDetailsErrorHandler(context, error) {
    console.log("nomineeDetails " + error);
  },
};
const getters = {
  getJwtToken: (state) => {
    if (!state.token) {
      state.token = sessionStorage.getItem('token')
    }
    return state.token;
  },
  getUserDetails: (state) => {
    if (!state.userPayload) {
      state.userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    }
    return { "name": state.userPayload.name, "email": state.userPayload.email, "phoneNumber": state.userPayload.phone_number }
  },
  isLoggedIn: (state) => {
    return state.token || sessionStorage.getItem('token');
  },
  getUserStatus: (state) => {
    if (!state.userPayload) {
      state.userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    }
    return state.userPayload ? state.userPayload['custom:user_signup_status'] : 'Unknown status';
  }, 
  getAccessToken: (state) => {
    if (!state.accessToken) {
      state.accessToken = sessionStorage.getItem('accessToken')
    }
    return state.accessToken;
    },
    getUser:(state)=> {
      if (!state.accessToken) {
        state.accessToken = sessionStorage.getItem('accessToken')
      }
      if(!sessionStorage.getItem('accessToken')){
        return null;
      }
      if(state.accessToken){
        const payload = JSON.parse(atob(state.accessToken.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);
         if(payload.exp > currentTime){
          state.loggedIn = true;
         }else
         state.loggedIn = false;
        }
        if (state.userPayload) {
          const userPayloadString = sessionStorage.getItem('userPayload');      
          if (userPayloadString) {
            try {
              state.userPayload = JSON.parse(userPayloadString);
            } catch (e) {
              console.error("Error parsing userPayload:", e);
              return null;
            }
          } else {
            console.warn("No userPayload found in sessionStorage.");
          }
        }
        if (state.userPayload && state.userPayload.name && state.userPayload.email && state.userPayload['custom:user_signup_status']) {
          return {
            name: state.userPayload.name,
            email: state.userPayload.email,
            userStatus: state.userPayload['custom:user_signup_status']
          };
        } else {
          console.warn("userPayload is not set or missing required fields.");
          return null;
        }
    },
    validateToken: (state) => {
      if (!state.accessToken) {
        state.accessToken = sessionStorage.getItem("accessToken");
      }
    
      if (state.accessToken) {
        const payload = JSON.parse(atob(state.accessToken.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        const expiringTime = payload.exp;
        const tenMinutesBeforeExpire = expiringTime - 600;
    
        if (currentTime >= expiringTime) {
          return 'expired';
        } else if (currentTime >= tenMinutesBeforeExpire) {
          return 'aboutToExpire';
        } else {
          return 'tokenValid';
        }
      } else {
        return 'noToken';
      }
    }
    
};
export const userStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};