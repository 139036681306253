<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="col-4 ms-auto d-lg-flex d-none my-auto pe-0 position-relative top-0 end-0 text-center justify-content-center flex-column">
              <img class="position-relative z-index-2" src="@/assets/img/email-otp.png" />
            </div>
            <div class="mx-auto col-lg-5 col-xl-4 col-md-7">
              <div class="card border shadow">
                <div class="card-body px-lg-5 py-lg-5 px-3">
                  <div class="text-center mb-4 info">
                    <div class="py-3 mx-auto text-center shadow icon icon-shape icon-xl rounded-circle bg-primary">
                      <img class="w-75 ms-2" src="@/assets/img/otp-logo.png">
                    </div>
                  </div>
                  <div class="mb-4 text-center text-muted">
                    <h4><b>Email Verification</b></h4>
                  </div>
                  <div class="text-sm text-left p-0 col-12 col-md-9 mb-3 w-100">(OTP sent on {{ maskedEmail }}) </div>
                  <Form role="form" @submit="onSubmit">
                    <div class="row gx-2 gx-sm-3">
                      <soft-input class="mb-0" placeholder="Enter OTP" v-model="otp" name="otp" id="businessName"
                        type="text" rules="required:true" maxlength="6" />
                    </div>
                    <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error
                        }}</small>
                    </div>
                    <div class="mt-4 d-flex justify-content-center mt-5">
                      <soft-button class="text-primary bg-white border border-primary"
                        @click="previouspage">previous</soft-button>
                      <soft-button ref="buttonRef" class="mt-0 ms-1" color="primary" type="submit"> Verify OTP
                      </soft-button>
                    </div>
                  </Form>
                </div>
                <div class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row">
                  Haven't received it?
                  <a id="resend" href="javascript:;" @click="resend"
                    class="text-info font-weight-bolder ms-sm-1 mt-2 mt-sm-0">Resend OTP</a>
                  <span v-if="timer > 0" class="ms-sm-1 mt-2 mt-sm-0">in {{ timer }} seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal" ref="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal- modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="justify-content-center text-center mt-4">
            <i class="fa-regular fa-circle-check fa-5x text-success"></i>
            <h3 class="text-success text-center pe-2 mt-3"> Success</h3>
          </div>
          <div class="modal-body mx-4">
            <h5 class="mb-3">Congratulations!</h5>
            <p class="text-justify">You've successfully completed the registration process. Welcome to OneS! Your
              account
              is now active and ready to use. Thank you for choosing us.</p>
            <P class="text-justify">If you have any questions or need assistance, feel free to reach out to our support
              team. We're here to help!</p>
          </div>
          <div class="text-center justify-content-center">
            <button type="button" class="btn bg-primary text-white" data-bs-dismiss="modal"
              @click="redirect">proceed</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { mapMutations } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { Form } from "vee-validate";

export default {
  name: "Emailverification",
  components: {
    SoftButton,
    SoftInput,
    Form,
  },
  data() {
    return {
      otp: "",
      count: 60,
      time: "",
      resendEnable: true,
      email: "",
      resetPassword: true,
      isSuccess: true,
      error: "",
      showModal:true,
    }
  },
  mounted() {
    this.setTime();
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.email = userPayload.email;
  },
  computed: {
    timer() {
      return this.count;
    },
    maskedEmail() {
      if (this.email) {
        let parts = this.email.split('@');
        let name = parts[0];
        let domain = parts.length > 1 ? parts[1] : '';
        let maskedName = name.substring(0, 1) + name.substring(1).replace(/./g, '*');
        return `${maskedName}@${domain}`;
      }
      return '';
    },
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    clearInterval(this.time);
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    setTime() {
      clearInterval(this.time);
      document.getElementById("resend").classList.remove("text-info");
      document.getElementById("resend").classList.add("text-secondary");
      this.resendEnable = false;
      this.count = 60;
      this.time = setInterval(this.myTimer, 1000);
    },
    myTimer() {

      this.count--;
      if (this.count == 0) {
        document.getElementById("resend").classList.remove("text-secondary");
        document.getElementById("resend").classList.add("text-info");
        this.resendEnable = true;
        clearInterval(this.time);
      }
    },
    redirect() {
      const userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
      userPayload['custom:user_signup_status'] = 'EMAIL_VERIFIED';
      sessionStorage.setItem('userPayload', JSON.stringify(userPayload));
      this.$store.commit('userStore/setUserPayload', userPayload);
      this.$router.push({ name: 'Dashboard' });
    },
    onSubmit() {
      let data = {
        "email": this.email,
        "otp": this.otp,
      };
      var self = this;
      this.$store
        .dispatch("userStore/emailVerification", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          new bootstrap.Modal(self.$refs.modal).show();
        })
        .catch(function (error) {
          self.error = error.server.response.data.message|| 'Email OTP verification failed. Please resend the OTP or contact customer service.';
          self.isSuccess = false;
        });
    },
    resend() {
      if (!this.resendEnable) return;
      let data = {
        "email": this.email
      };
      var self = this;
      this.$store
        .dispatch("userStore/resendEmailOtp", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.setTime();
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
        });
    },
    previouspage() {
      this.$router.push("/bankaccountregister");
    },
  }
};
</script>
