<template>
  <section class="features-3 py-6">
    <div class="container">
      <div class="row text-center justify-content-center">
        <div class="col-lg-7">
          <h3>Explore Our Diverse Mutual Fund Options</h3>
          <p class="text-justify">
            Our carefully curated Mutual Fund categories offer you diversified Investment avenues. Explore options that
            resonate with your risk appetite and goals
          </p>
        </div>
      </div>
      <div class="row mt-6">
        <div class="col-lg-4 mb-lg-0 mb-4 pb-4">
          <div class="card-body pb-2 h-50 shadow">
            <h5 class="title font-weight-normal text-primary">
              <i class="fa fa-line-chart" aria-hidden="true"></i>
              Equity Funds
            </h5>
            <p class="card-description">Aim for high returns with long-term equity Investments</p>
            <router-link :to="{ path: 'explore', query: { name: 'EQUITY' }}" >
            <span  class="card-button text-primary">Know More</span>
          </router-link>
            <i class="fas fa-arrow-right text-xs ms-1 text-primary"></i>
          </div>
          <div class="mt-4">
            <div class="card-body pb-3 pt-md-0 pt-xl-4 h-50 shadow">
              <h5 class="title font-weight-normal text-primary">
                <i class="fas fa-piggy-bank"></i>
                Tax-Saving Funds (ELSS)
              </h5>
              <p class="card-description">Enjoy Tax benefits and growth with Equity-Linked Savings Schemes</p>
              <router-link :to="{ path: 'explore', query: { name: 'ELSS' }}" >
            <span  class="card-button text-primary">Know More</span>
          </router-link>
              <i class="fas fa-arrow-right text-xs ms-1 text-primary"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-4 pb-4">
          <div class="card-body pb-3 h-50 shadow">
            <h5 class="title font-weight-normal text-primary">
              <i class="fa-solid fa-vault"></i>
              Debt Funds
            </h5>
            <p class="card-description">Stable, low-risk investments for consistent Income and Capital safety.</p>
             <router-link :to="{ path: 'explore', query: { name: 'DEBT' }}" >
            <span class="card-button text-primary">Know More</span>
          </router-link>
            <i class="fas fa-arrow-right text-xs ms-1 text-primary"></i>
          </div>

          <div class="mt-4">
            <div class="card-body pb-3 h-50 shadow">
              <h5 class="title font-weight-normal text-primary">
                <i class="fa-solid fa-droplet"></i>
                Liquid Funds
              </h5>
              <p class="card-description">Opt for quick liquidity and short-term returns with Liquid Funds</p>
              <router-link :to="{ path: 'explore', query: { name: 'LIQUID' }}" >
            <span  class="card-button text-primary">Know More</span>
          </router-link>
              <i class="fas fa-arrow-right text-xs ms-1 text-primary"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-4 pb-4">
          <div class="card-body pb-3 h-50 shadow">
            <h5 class="title font-weight-normal text-primary">
              <i class="fa-solid fa-scale-balanced"></i>
              Hybrid Funds
            </h5>
            <p class="card-description">Blend equity growth and Debt stability for a diversified Investment.</p>
            <router-link :to="{ path: 'explore', query: { name: 'HYBRID (NC)'}}" >
            <span  class="card-button text-primary">Know More</span>
          </router-link>
            <i class="fas fa-arrow-right text-xs ms-1 text-primary"></i>
          </div>
          <div class="mt-4">
            <div class="card-body pb-3 h-50 shadow">
              <h5 class="title font-weight-normal text-primary">
                <i class="fa fa-globe" aria-hidden="true"></i>
                International Funds
              </h5>
              <p class="card-description">Diversify with Global market exposure through International Funds.</p>
              <router-link :to="{ path: 'explore', query: { name: 'FOF'}}" >
            <span  class="card-button text-primary">Know More</span>
          </router-link>
              <i class="fas fa-arrow-right text-xs ms-1 text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
