  const nonProtectedPaths = [
    '/',
    '/signup',
    '/signin',
    '/mobileverification',
    '/resetpassword',
    '/newpassword',
    '/explore',
    '/NFO',
    '/details/:isin',
    '/ones-home'
  ];
  function isOpenPath(path) {
    if (nonProtectedPaths.includes(path)) {
        return true;
    }else{
        return false;
    }
  }
  
  // Export the lists for use in other parts of your application
  export { nonProtectedPaths,isOpenPath };