<template>
  <div class="form-group ms-2">
    <Field
      :class="getClasses(size, success, error)"
      :name="name"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :rules="rules"
      v-bind="$attrs"
      :v-slot="{ field }"
      >
      <select
      class="form-select form-control border border-light-mauve"
        :placeholder="placeholder"
        :id="id"
        :disabled="isDisabled"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <option v-for="(option, index) in options" :key="index" :value="option.value" >{{ option.text }}</option>
</select>
    </Field>
    <div class="error-height">
    <ErrorMessage
      as="small"
      class="mt-3 text-danger font-weight-light text-xs"
      :name="name"
    />
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "SoftSelect",
  components: {
    Field,
    ErrorMessage,
  },
  prop: ['modelValue'],
  emits: ['update:modelValue'],
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Type here...",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array, Object],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;
      sizeValue = size ? `form-control-${size}` : null;
      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }
      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
    hasdisable: (disabled) => (disabled ? "disabled" : null),
  },
};
</script>
