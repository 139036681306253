<template>
  <div class="form-check">
    <div>
    <Field
        :id="id"
        class="form-check-input border border-light-mauve"
        type="radio"
        :name="name"
        :checked="checked"
        :rules="rules"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      </Field>
    <label class="custom-control-label" :for="id">
      <slot />
    </label>
  </div>
    </div>
</template>

<script>
import { Field } from "vee-validate";
export default {
  name: "SoftRadio",
  components: {
    Field,
  },
  prop: ['modelValue'],
  emits: ['update:modelValue'],
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array, Object],
      default: "",
    },
  },
};
</script>
