<template>
  <div class="form-check">
    <div>
      <Field
        :id="id"
        class="form-check-input border border-light-mauve"
        type="checkbox"
        :name="name"
        :checked="checked"
        :rules="rules"
        v-bind="$attrs"
      >
      </Field>

      <slot name="label"> </slot>
    </div>
    <div>
      <div class="error-height">
      <ErrorMessage
        as="small"
        class="mt-3 text-danger font-weight-light text-xs"
        :name="name"
      />
    </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "SoftCheckbox",
  components: {
    Field,
    ErrorMessage,
  },

  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array, Object],
      default: "",
    },
  },
};
</script>