<script >
export default {
  name: " Detail Header",
  props:{
  title: {
    type: [String, Object],
    default: "",
    text: String,
    variant: {
      validator(value) {
        return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(value);
      },
    },
    class: {
      type: String,
      default: "",
    },
  },
  description: {
    type: [String, Object],
    default: "",
    text: String,
    class: String,
  },
  firstpoint: {
    type: [String, Object],
    default: "",
    text: String,
    class: String,
  },
  center: {
    type: Boolean,
    default: false,
  },
  secondpoint: {
    type: [String, Object],
    default: "",
    text: String,
    class: String,
  },
  thirdpoint: {
    type: [String, Object],
    default: "",
    text: String,
    class: String,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
}
};
</script>
<template>
  <header class="header-2">
    <div
      class="page-header py-4"
      v-if="title"
    >
        <div class="row">
          <div
            :class="`${$attrs.class ?? 'col-lg-7'} ${
              center ? 'text-center mx-auto' : ''
            }`"
          >
            <component
              :is="title.variant ? title.variant : 'h3'"
              class="text-primary"
              :class="title.class"
            >
              {{ typeof title == "object" ? title.text : title }}
            </component>
            <p class=" text-justify" :class="description.class">
              {{
                typeof description == "object" ? description.text : description
              }}
            </p>
            <slot />
          </div>
          <div
            :class="`${$attrs.class ?? 'col-lg-5'} ${
              center ? 'text-center mx-auto ' : ''
            }`"
          >
          <div class="p-3 info-horizontal d-flex align-items-center">
            <i class="fa-solid fa-coins text-primary"></i>
            <div class="description ps-3">
            <h5 class=" mb-0" :class="firstpoint.class">
              {{
                typeof firstpoint == "object" ? firstpoint.text : firstpoint
              }}
            </h5>
            </div>
            </div>
            <div class="p-3 info-horizontal d-flex align-items-center">
            <i class="fa-solid fa-coins text-primary"></i>
            <div class="description ps-3">
              <h5 class="  mb-0" :class="secondpoint.class">
              {{
                typeof secondpoint == "object" ? secondpoint.text : secondpoint
              }}
            </h5>
            </div>
            </div>
            <div class="p-3 info-horizontal d-flex align-items-center">
            <i class="fa-solid fa-coins text-primary"></i>
            <div class="description ps-3">
              <h5 class="" :class="thirdpoint.class">
              {{
                typeof thirdpoint == "object" ? thirdpoint.text : thirdpoint
              }}
            </h5>
            </div>
            </div>
            <slot />
          </div>
        </div>
      </div>
   
  </header>
</template>
