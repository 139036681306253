<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-column flex-md-row">
      <div class="col p-0">
        <div class="mt-6 page-header min-height-160 border-radius-xl dashboard-headerimage">
        </div>
        <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5">
          <div class="row w-100 px-0">
            <div class="col-12">
              <div class="card-header p-0">
                <h4 class="mb-2 ms-3">Investment Overview<br><span class="text-sm ms-2">As of {{ currentDate }}</span>
                </h4>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <outlined-counter-card :duration="500" prefix="₹" :count="totalInvested" title="Invested Value" />
                  <outlined-counter-card prefix="₹" :count="totalCurrent" title="Current Value" percentage="%" />
                  <outlined-counter-card class="mt-4 mt-lg-0" :duration="500" prefix="₹" :count="totalPNL"
                    title="Total P & L" />
                  <outlined-counter-card :duration="500" class="mt-4 mt-lg-0" :count="totalReturn" title="Returns"
                    suffix="%" :is-percentage="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="totalValue.amt && totalValue.fmt">
          <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5">
            <div class="mt-4 row w-100 mb-3 mt-4">
              <div class="col-lg-6 ms-auto">
                <reports-doughnut-chart :chart-id="chartid" :title="chartTitle" :total-consumption="totalValue.amt"
                  :consumption-unit="totalValue.fmt" :rooms="schemeTypeChart" :button-title="buttonTitles" />
              </div>
              <div class="col-lg-6 ms-auto">
                <reports-doughnut-chart :chart-id="id" :title="chartsTitle" :total-consumption="totalValue.amt"
                  :consumption-unit="totalValue.fmt" :rooms="schemeNameChart" :button-title="buttonTitle" />
              </div>
            </div>
          </div>
        </div>
        <div class="card card-body blur shadow mx-3 mx-md-4 mt-6 mb-5">
          <LandingpageCards />
        </div>
      </div>
    </div>
  </div>
  <div class="modal" ref="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal- modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header border-0 d-flex justify-content-end">
          <button type="button" class="btn-close text-black btn-close-opacity" data-bs-dismiss="modal"
            aria-label="Close">
            <i class="fa-solid fa-xmark text-primary fa-lg"></i>
          </button>
        </div>
        <div class="justify-content-center text-center mt-4">
          <i class="fa-solid fa-building-columns fa-5x text-primary"></i>
          <h3 class="text-center pe-2 mt-3"> Authorization</h3>
        </div>

        <div class="modal-body mx-md-4 my-3">
          <Form role="form" @submit.prevent="onSubmit">
            <div class="ms-md-5 ms-4">
              <div>
                <label class="ones-label-size ms-xl-4 me-1 ms-md-4">Account Number</label>
                <span class="ms-xl-4 ps-xl-2 ms-md-4 ms-2">{{ maskedAccountNumber }}</span>
              </div>
              <div>
                <label class="ones-label-size ms-xl-4 ms-md-4 ms-1 pe-2 pe-xl-1">Bank Name</label>
                <span class="ms-xl-4 ms-md-2 ps-xl-5 ps-md-5 ps-lg-5 ms-3 ps-4">{{ bankName }}</span>
              </div>
            </div>
            <div class="text-center"><small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
            </div>
            <div v-if="isRetryMandateEligible" class="text-center text-danger"><small class="text-xs">Auto pay is
                currently unavailable.<br> Please retry after completing UCC authentication.</small></div>
            <div v-else class="text-center">
              <soft-button class="my-4" type="submit" color="primary" full-width size="lg">{{ buttonText }}
              </soft-button>
            </div>
            <div v-if="isRetryMandateEligible" class="text-center">
              <soft-button class="my-4" type="button" color="primary" full-width size="lg" @click="retryMandates">Try
                again</soft-button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" ref="modallink" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal- modal-dialog-centered" role="document">
      <div class="modal-content">
        <div v-if="errorOccurred" class="modal-header border-0 d-flex justify-content-end">
          <button type="button" class="btn-close text-black btn-close-opacity" data-bs-dismiss="modal"
            aria-label="Close">
            <i class="fa-solid fa-xmark text-primary fa-lg"></i>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="card card-plain">
            <div v-if="success">
              <div class="mt-0 align-item-center ms-1">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
            </div>
            <div v-if="success" class="card-body ps-4 py-3">
              <div>
                <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">success </h3>
                <h6 class="text-center me-4 ps-4 mb-3 text-capitalize">{{ statusMessage }}</h6>
              </div>
            </div>
            <div v-if="errorOccurred" class="align-item-center ms-1">
              <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
              </svg>
            </div>
            <div v-if="errorOccurred" class="card-body ps-4 py-3">
              <div>
                <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">failed !</h3>
                <h6 class="text-center me-4 ps-4 mb-3 text-capitalize">{{ statusMessage }}</h6>
              </div>
            </div>
            <div class="mb-4">
              <label class="ones-label-size ms-xl-0 me-1 ms-md-0">{{ bankName }}</label>
              <span class="ms-xl-4 ps-xl-2 ms-md-4 ms-2 text-sm">{{ maskedAccountNumber }}</span>
            </div>
            <div v-if="errorOccurred" class="d-flex justify-content-center mt-3 mx-4 mb-3">
              <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary" type="button" @click="retry()"> Try
                again
              </soft-button>
            </div>
            <div v-if="success" class="text-center justify-content-center px-4 mb-3 mt-3">
              <soft-button type="button" class="btn bg-primary text-white w-100"
                data-bs-dismiss="modal">Done</soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportsDoughnutChart from "@/components/ReportsDoughnutChart.vue";
import OutlinedCounterCard from "../common/components/OutlinedCounterCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import LandingpageCards from "../common/components/LandingpageCards.vue";
import { mapState } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import SoftButton from "@/views/common/components/SoftButton.vue";
export default {
  name: "Dashboard",
  components: {
    ReportsDoughnutChart,
    OutlinedCounterCard,
    LandingpageCards,
    SoftButton,
  },
  data() {
    return {
      showMenu: false,
      currentDate: this.getCurrentDate(),
      buttonTitle: "Discover our top-performing mutual funds, organized by sector allocation, to enhance your portfolio's diversification and growth potential.",
      buttonTitles: "Asset allocation is an investment strategy that involves distributing a portfolio's investments among different asset classes in order to achieve a specific risk and return objective.",
      chartid: "chart-consumption",
      id: "chart-mutualFund",
      chartTitle: "Asset Allocation",
      chartsTitle: "Top Mutual Funds",
      accountNumber: "",
      ifscCode: "",
      linkUrl: "",
      iframeSrc: '',
      toDate: "",
      fromDate: this.formatDate(new Date()),
      mandateId: "",
      statusMessage: "",
      success: false,
      errorOccurred: false,
      bankName: "Not Available",
      mandateStatus: "",
      cognitoStatus: "",
      schemes: [],
      isRetryMandateEligible: false,
      error: "",
      isSuccess: true,
      buttonText: 'Authorize',
    };

  },
  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.getPortfolioApi();
    this.linkUrl = sessionStorage.getItem("linkUrl");
    const currentUrl = new URL(window.location.href);
    console.log("current URL:", currentUrl);
    const hash = window.location.hash;
    const hashParams = new URLSearchParams(hash.slice(hash.indexOf('?')));
    const status = hashParams.get('status');
    const message = hashParams.get('message');
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.cognitoStatus = userPayload['custom:user_signup_status'];
    if (status) {
      this.accountNumber = sessionStorage.getItem("Account Number");
      this.bankName = sessionStorage.getItem("bank Name");
      this.processStatus(status, message);
      this.showModal();
    } else if (this.cognitoStatus !== "MANDATE_REGISTRATION_COMPLETED") {
      this.modal();
      this.getAllMandates();
    }
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "color",
      "showSidenav",
    ]),
    maskedAccountNumber() {
      if (this.accountNumber) {
        return this.accountNumber.slice(0, -4).replace(/./g, '*') + this.accountNumber.slice(-4);
      } else {
        return "Not Available";
      }
    },
    maskedIfscCode() {
      if (this.ifscCode) {
        return this.ifscCode.slice(0, 4) + this.ifscCode.slice(4, -3).replace(/./g, '*') + this.ifscCode.slice(-3);
      } else {
        return "unavailable";
      }
    },
    schemeTypeChart() {
      let chartData = [{ name: "Equity", percentage: 0, amount: 0 },
      { name: "Debt", percentage: 0, amount: 0 },
      { name: "ELSS", percentage: 0, amount: 0 },
      { name: "Hybrid", percentage: 0, amount: 0 },
      { name: "Other", percentage: 0, amount: 0 }]
      if (this.schemes && this.schemes.length > 0) {
        var total = this.schemes.reduce((sum, a) => sum + a.amount, 0);
        var schemeMap = Object.groupBy(this.schemes, ({ schemeType }) => schemeType);
        if (schemeMap) {
          for (const [key, value] of Object.entries(schemeMap)) {
            if (value) {
              var data = chartData.find((c) => key && c.name.toLowerCase() == key.toLowerCase());
              if (data) {
                data.amount = data.amount + value.map((v) => v.amount).reduce((sum, a) => sum + a, 0);
                data.percentage = Math.round((data.amount / total) * 10000) * .01;
              } else {
                var otherData = chartData.find((c) => c.name == 'Other');
                otherData.amount = otherData.amount + value.map((v) => v.amount).reduce((sum, a) => sum + a, 0);
                otherData.percentage = Math.round((otherData.amount / total) * 10000) * .01;
              }
            }
          }
        }
      }
      return chartData;
    },
    schemeNameChart() {
      var schemeArray = [];
      if (this.schemes && this.schemes.length > 0) {
        var total = this.schemes.reduce((sum, a) => sum + a.amount, 0);
        var schemeGroup = Object.groupBy(this.schemes, ({ fundId }) => fundId);
        var sumMap = {};
        for (const [key, value] of Object.entries(schemeGroup)) {
          if (value) {
            var sum = [...value].reduce((sum, a) => sum + a.amount, 0);
            sumMap[key] = sum;

          }
        }
        var topScheme = Object.keys(schemeGroup).sort((a, b) => sumMap[b] - sumMap[a])?.slice(0, 5);
        for (let item of topScheme) {
          var data = {};
          data.name = schemeGroup[item]?.[0].schemeName;
          data.amount = sumMap[item];
          data.percentage = (Math.round((data.amount / total) * 10000) * .01).toFixed(2);
          schemeArray.push(data);
        }
      }
      return schemeArray;
    },
    totalValue() {
      var totalFormatted = { amt: 0, fmt: "RS" };
      if (this.schemes && this.schemes.length > 0) {
        var total = this.schemes.reduce((sum, a) => sum + a.amount, 0);
        if (total >= 10000000) {
          totalFormatted.amt = (total / 10000000).toFixed(2);
          totalFormatted.fmt = "Crores";
        } else if (total >= 1000) {
          totalFormatted.amt = (total / 100000).toFixed(2);
          totalFormatted.fmt = "Lakhs";
        } else {
          totalFormatted.amt = total.toFixed(2);
          totalFormatted.fmt = "RS";
        }
      }
      return totalFormatted;
    },
    totalInvested() {
      var value = 0
      if (this.schemes && this.schemes.length > 0) {
        value = (this.schemes.reduce((sum, a) => sum + a.amount, 0)).toFixed(2);

      }
      return value;
    },
    totalCurrent() {
      var value = 0
      if (this.schemes && this.schemes.length > 0) {
        value = (this.schemes.reduce((sum, a) => sum + a.currentValue, 0)).toFixed(2);

      }
      return value;
    },
    totalPNL() {
      return this.totalCurrent - this.totalInvested;
    },
    totalReturn() {
      if (this.totalInvested == 0) {
        return 0;
      } else {
        return (Math.round(((this.totalCurrent - this.totalInvested) / this.totalInvested) * 10000) * .01).toFixed(2);
      }

    }
  },
  methods: {
    getPortfolioApi() {
      var self = this;
      this.$store
        .dispatch("productStore/getPortfolio", { root: true })
        .then(response => {
          self.schemes = response.server.data;
          if (self.schemes) {
            for (let item of self.schemes) {
              self.$store
                .dispatch("schemeStore/getSchemeDetails", item.fundId, { root: true })
                .then(function (response) {
                  item.schemeName = response.server.data.schemeName;
                  item.schemeType = response.server.data.schemeType;
                  item.isin = response.server.data.schemeDetails.isin;
                  self.$store
                    .dispatch("schemeStore/getNav", item.isin, { root: true })
                    .then(function (response) {
                      item.nav = response.server.data.navValue;
                      item.currentValue = (Math.round(item.nav * item.quantity * 100) * .01);
                      item.returns = (Math.round(((item.currentValue - item.amount) / item.amount) * 10000) * .01);
                      sessionStorage.setItem("schemes", JSON.stringify(self.schemes));
                    }).catch(function (error) {
                      item.nav = 0;
                      item.currentValue = 0;
                      item.returns = 0;
                      sessionStorage.setItem("schemes", JSON.stringify(self.schemes));
                      console.log(error);
                    });
                }).catch(function (error) {
                  item.schemeName = 'Not Available';
                  item.schemeType = 'Not Available';
                  item.isin = 'Not Available';
                  console.log(error);
                });
            }
          }
        }).catch(function (error) {
          console.log(error);
        });
    },
    getCurrentDate() {
      const today = new Date();
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return today.toLocaleDateString(undefined, options);
    },
    modal() {
      const modalElement = this.$refs.modal;
      const modalOptions = {
        backdrop: 'static',
        keyboard: false,
        focus: true
      };
      const myModal = new bootstrap.Modal(modalElement, modalOptions);
      myModal.show();
      document.body.classList.add('modal-open');
    },
    processStatus(status, message) {
      switch (status.toLowerCase()) {
        case 'success':
          this.statusMessage = message;
          this.success = true;
          this.errorOccurred = false;
          this.mandateStatus = "MANDATE_REGISTRATION_COMPLETED";
          this.mandateStatusUpdate();
          this.updateUserSignupStatus();
          break;
        case 'cancel':
          this.statusMessage = message;
          this.success = false;
          this.errorOccurred = true;
          this.mandateStatus = "MANDATE_RETRY_INITIATED";
          this.mandateStatusUpdate();
          break;
        case 'failure':
          this.statusMessage = message;
          this.success = false;
          this.errorOccurred = true;
          this.mandateStatus = "MANDATE_REGISTRATION_FAILED";
          this.mandateStatusUpdate();
          break;
        default:
          this.statusMessage = 'Unknown status';
          this.success = false;
          this.errorOccurred = false;
          this.mandateStatus = "MANDATE_INITIATED";
          this.mandateStatusUpdate();
          break;
      }
    },
    showModal() {
      const modalElement = this.$refs.modallink;
      const modalOptions = {
        backdrop: 'static',
        keyboard: false,
        focus: true
      };
      const myModal = new bootstrap.Modal(modalElement, modalOptions);
      myModal.show();
    },
    onSubmit() {
      let data = {
        "mandateId": this.mandateId,
      };
      var self = this;
      this.$store
        .dispatch("productStore/getMandatesId", data, { root: true })
        .then(function (response) {
          self.isSuccess = true;
          self.linkUrl = response.server.data;;
          const redirectUri = encodeURIComponent(window.location.origin + "/#/dashboard");
          self.linkUrl = self.linkUrl.replace(/(redirect_url=).*?(&)/, '$1' + redirectUri + '$2');
          window.location.href = self.linkUrl;
          sessionStorage.setItem("linkUrl", self.linkUrl);
        })
        .catch(function (error) {
          self.error = error.message || 'Mandate is under processing. Please retry after 5 minutes.';
          self.isSuccess = false;
          self.buttonText = 'Retry Authorization';
        });
    },
    closeModal() {
      const modalInstance = bootstrap.Modal.getInstance(this.$refs.modallink);
      if (modalInstance) {
        modalInstance.hide();
        document.body.classList.remove('disable-elements');
      }
    },
    getAllMandates() {
      var self = this;
      this.$store
        .dispatch("productStore/getMandates", { root: true })
        .then(function (response) {
          var mandateData = response.server.data;
          if (mandateData && mandateData.length > 0 && mandateData[0].mandateCfmId) {
            self.accountNumber = response.server.data[0].accountNumber;
            self.bankName = response.server.data[0].bankName;
            self.mandateId = response.server.data[0].mandateCfmId;
            sessionStorage.setItem("mandateId", self.mandateId);
            sessionStorage.setItem("Account Number", self.accountNumber);
            sessionStorage.setItem("bank Name", self.bankName);
          } else {
            self.isRetryMandateEligible = true;
            self.retryMandates();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.retryMandates();
        });
    },
    retryMandates() {
      if (this.isRetryMandateEligible) {
        var self = this;
        this.$store
          .dispatch("productStore/retryMandate", { root: true })
          .then(function () {
            self.getAllMandates();
            self.isRetryMandateEligible = false;
          })
          .catch(function (error) {
            console.log(error);
            self.isRetryMandateEligible = true;
          });
      }
    },
    mandateStatusUpdate() {
      let data = {
        "mandateId": sessionStorage.getItem("mandateId"),
        "status": this.mandateStatus,
      };
      this.$store
        .dispatch("productStore/updateMandateStatus", data, { root: true })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    },
    retry() {
      window.location.href = this.linkUrl;
    },
    updateUserSignupStatus() {
      const userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
      userPayload['custom:user_signup_status'] = 'MANDATE_REGISTRATION_COMPLETED';
      sessionStorage.setItem('userPayload', JSON.stringify(userPayload));
    }
  },
};
</script>