<template>
  <div class="container-fluid px-0">
    <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5 px-0">
      <div class="row flex-column flex-md-row">
        <div class="col-12 p-0 ps-2 z-index-sticky text-black">
          <div>
            <div class="container me-0 position-relative">
              <div class="table-responsive pt-3">
                <table id="datatable-search" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  header_width">
                        Scheme name
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        Invested Value
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Current Value
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Current return
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Action
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";

export default {
  name: "Table",
  components: {
  },
  data() {
    return {
      fiterText: "",
      direction: "",
      pageNo: 0,
      schemes: [],
    };
  },
  mounted() {
    const storeValue = sessionStorage.getItem("schemes");
    if (storeValue) {
      const parsedSchemes = JSON.parse(storeValue);
      if (parsedSchemes) {
        const schemeGroup = Object.groupBy(parsedSchemes, ({ fundId }) => fundId);
        const sumMap = {};
        for (const [key, value] of Object.entries(schemeGroup)) {
          if (value) {
            const sum = value.reduce((sum, a) => sum + a.amount, 0);
            sumMap[key] = sum;
          }
        }
        const Scheme = Object.keys(schemeGroup);
        for (let obj of Scheme) {
          const data = [];
          data.push(schemeGroup[obj][0].schemeName);
          data.push(sumMap[obj]);
          data.push(schemeGroup[obj].reduce((sum, a) => sum + a.currentValue, 0));
          data.push(((data[2] - data[1]) / data[1] * 100).toFixed(2));
          data.push(schemeGroup[obj][0].fundId);
          this.schemes.push(data);
        }
        this.updateTable(this.schemes, 0);
      }
    }
  },
  unmounted() {
    if (this.dataTab != null) {
      this.dataTab.destroy();
    }
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    updateTable(tbody, pageNo) {
      var self = this;
      if (self.dataTab != null) {
        this.dataTab.destroy();
      }
      this.dataTab = new DataTable("#datatable-search", {
        searchable: false,
        fixedHeight: false,
        perPageSelect: false,
        perPage: 5,
        columns: [
          {
            select: 0, sortable: false,
            render: function (data, td, rowIndex, cellIndex) {
              const items = data.split('~~');
              var tableData = `<div class="text-wrap" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (items[0]) {
                var schemeName = items[0].replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
                tableData += `<div><span class="text-sm font-weight-bold">${schemeName}</span></div>`;
              }
              if (items[1]) {
                tableData += `<div><span class="text-xs badge badge-secondary"><i class="fa-solid fa-tags me-2"></i>${items[1]}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 1, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              };
              const formatted = Number(data).toLocaleString('en', options);
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span>₹ ${formatted}</span></div>`;
            }
          },
          {
            select: 2, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              };
              const formatted = Number(data).toLocaleString('en', options);
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span>₹ ${formatted}</span></div>`;
            }
          },
          {
            select: 3, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span> ${data}%</span></div>`;
            }
          },
          {
            select: [4], sortable: false, render: function (data, td, rowIndex, cellIndex) {
              return `<div class="text-sm font-weight-normal text-capitalize td-padding" name='td_${cellIndex}' data-row='${rowIndex}'><button type="button" class="btn btn-sm mb-0 bg-primary text-white"  data-bs-toggle="modal" data-bs-target="#redemption_${data}">REDEEM</button></div>`;
            }
          },
        ],
        data: {
          "data": this.schemes
        }
      });
      this.dataTab.page(Number(pageNo ? pageNo : 1))

    },

  }
};
</script>