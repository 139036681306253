<template>
  <div class="mt-4 card  mt-md-0">
    <div class="p-3 pb-0 card-header " v-if="pages.length!=0">
      <div class="d-flex align-items-center">
        <h6  >Scheme Holdings</h6>
      </div>
    </div>
    <div class="card-body  my-3 d-flex justify-content-center justify-content-middle" v-if="pages.length==0">
      <div class="d-flex align-items-center">
       Companywise breakdown not available.
      </div>
    </div>
    <div class="px-3 pt-0 pb-2 card-body" v-if="pages.length!=0">
      <div class="p-0 table-responsive" >
        <table class="table mb-0 align-items-center justify-content-center">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Company
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Allocation
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Investment
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Sector
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Asset Class
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Rating
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) of pages"
              :key="index"
            >
              <td  v-for="(cell,index) of item"
              :key="index">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ cell?cell:'-' }}
                </p>
              </td>
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PagesCard",
  props: {
    pages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
