<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <section>
    <div class="py-9 container">
      <div class="row">
        <div class="mx-auto col-lg-5 col-md-7">
          <div class="mb-5 card shadow">
            <div class="card-body px-3 mx-3">
              <h4 class="mb-3 font-weight-bolder text-center">Reset password</h4>
              <p class="mb-3 justify-content-center text-center text-buccaneer">
                Enter the email address associated with your OneS account.
              </p>
              <Form role="form" @submit="onSubmit">
                <div class="mb-3">
                  <soft-input id="email" type="email" rules="required:true|email" v-model="email" placeholder="Email"
                    name="email" />
                </div>
                <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
                </div>
                <div class="text-center">
                  <soft-button color="primary" type="submit" class="my-4 w-100 mb-2">proceed</soft-button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from "@/views/Navbars/Navbar.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
export default {
  name: "Resetpassword",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    Form,
  },
  data() {
    return {
      email: "",
      isSuccess: true,
      resetPassword: true,
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    this.$store.state.showFooter = false;
    this.$store.state.showSidenav = false;
    const emailId = this.$route.query.email;
    if (emailId) {
      this.email = emailId;
    } else {
      this.errorMessage = "Please enter your email id.";
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    onSubmit() {
      sessionStorage.setItem("resetPasswordEmail", this.email);
      let data = {
        "email": this.email,
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/forgotPassword", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          self.$router.push("/newpassword");
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });
    },
  },
};
</script>
