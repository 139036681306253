import requestCommon from "@/components/web-interface/requestCommon";

const state = {
};
const actions = {
    orders(context, data) {
        return requestCommon.postInfo(
            "/us/orders/" + data.type,
            null,
            data,
            context,
            "ordersSuccessHandler",
            "ordersErrorHandler"
        );
    },
    getMandates(context, data) {
        return requestCommon.getInfo(
            "/us/users/mandates",
            data,
            null,
            context,
            "getMandatesSuccessHandler",
            "getMandatesErrorHandler"
        );
    },
    getMandatesId(context, data) {
        return requestCommon.getInfo(
            "/us/users/mandates/" + data.mandateId,
            null,
            null,
            context,
            "mandatesIdSuccessHandler",
            "mandatesIdErrorHandler"
        );
    },
    getStatus(context, data) {
        let fill = "";
        if (data.filter != null) {
            fill = "&filter=" + data.filter
        }
        return requestCommon.getInfo(
            "/us/orders?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
            "&sortBy=" + data.sortBy + "&direction=" + data.direction + fill,
            data,
            null,
            context,
            "getStatusSuccessHandler",
            "getStatusErrorHandler"
        );
    },
    redemption(context, data) {
        return requestCommon.postInfo(
            "/us/orders/" + data.folioNo + "/" + data.quantity,
            null,
            data,
            context,
            "redemptionSuccessHandler",
            "redemptionErrorHandler"
        );
    },
    getPortfolio(context, data) {
        let fill = "";
        if (data.filter != null) {
            fill = "&filter=" + data.filter
        }
        return requestCommon.getInfo(
            "/us/portfolios" + fill,
            data,
            null,
            context,
            "getPortfolioSuccessHandler",
            "getPortfolioErrorHandler"
        );
    },
    updateMandateStatus(context, data) {
        return requestCommon.patchInfo(
            "/us/users/mandates/" + data.mandateId + "/" + data.status,
            null,
            data,
            context,
            "updateMandateStatusSuccessHandler",
            "updateMandateStatusErrorHandler"
        );
    },
    confirmOrder(context, data) {
        return requestCommon.putInfo(
            "/us/orders/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            "confirmOrderSuccessHandler",
            "confirmOrderErrorHandler"
        );
    },
    redemptionConfirmation(context, data) {
        return requestCommon.patchInfo(
            "/us/orders/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            "redemptionConfirmationSuccessHandler",
            "redemptionConfirmationErrorHandler"
        );
    },
    resentOtp(context, data) {
        return requestCommon.patchInfo(
            "/us/orders/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            " resentOtpSuccessHandler",
            " resentOtpErrorHandler"
        );
    },
    retryMandate(context, data) {
        return requestCommon.patchInfo(
            "/us/users/mandates",
            null,
            data,
            context,
            "retrymandateSuccessHandler",
            "retrymandateErrorHandler"
        );
    }

};
const mutations = {
    ordersSuccessHandler(context, data) {
        console.log(data);
    },
    ordersErrorHandler(context, error) {
        console.log(error);
    },
    getMandatesSuccessHandler(context, data) {
        console.log(data);
    },
    getMandatesErrorHandler(context, error) {
        console.log(error);
    },
    mandatesIdSuccessHandler(context, data) {
        console.log(data);
    },
    mandatesIdErrorHandler(context, error) {
        console.log(error);
    },
    getStatusSuccessHandler(context, data) {
        console.log(data);
    },
    getStatusErrorHandler(context, error) {
        console.log(error);
    },
    redemptionSuccessHandler(context, data) {
        console.log(data);
    },
    redemptionErrorHandler(context, error) {
        console.log(error);
    },
    getPortfolioSuccessHandler(context, data) {
        console.log(data);
    },
    getPortfolioErrorHandler(context, error) {
        console.log(error);
    },
    updateMandateStatusSuccessHandler(context, data) {
        console.log(data);
    },
    updateMandateStatusErrorHandler(context, error) {
        console.log(error);
    },
    confirmOrderSuccessHandler() {
        console.log("successfull otp verification");
    },
    confirmOrderErrorHandler(context, error) {
        console.log("otp verification failed" + error);
    },
    redemptionConfirmationSuccessHandler(context, data) {
        console.log(data);
    },
    redemptionConfirmationErrorHandler(context, error) {
        console.log(error);
    },
    resentOtpSuccessHandler() {
        console.log("successfull resent otp");
    },
    resentOtpErrorHandler(context, error) {
        console.log("otp resent failed" + error);
    },
    retrymandateSuccessHandler() {
        console.log("successfull resent otp");
    },
    retrymandateErrorHandler(context, error) {
        console.log("otp resent failed" + error);
    },
    
};
const getters = {

};
export const productStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

