<template>
    <div class="datepicker-container" @blur="closeCalendar" tabindex="0">
        <div class="input-group">
            <input type="text" class="form-control input-date border border-light-mauve" placeholder="Select SIP Date"
                :value="formattedDate" />
            <span class="input-group-text border border-light-mauve" @click="toggleCalendar">
                <i class="fa-solid fa-calendar-days"></i>
            </span>
        </div>
        <div v-if="showCalendar" class="calendar-popup">
            <div class="header">
                Choose SIP Installment Date
            </div>
            <div class="calendar">
                <div v-for="day in days" :key="day" class="day"
                    :class="{ 'selected': day === selectedDay, 'disabled': !isDayEnabled(day) }"
                    @click="selectDay(day)">
                    {{ day }}
                </div>
            </div>
            <div class="subtitle text-xs">
                Next SIP Installment on{{ formattedSubtitle }}
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn bg-primary text-white w-50" @click="confirmDate">UPDATE</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            showCalendar: false,
            selectedDay: this.modelValue,
            days: Array.from({ length: 31 }, (_, i) => i + 1),
            showPopover: false,
        };
    },
    computed: {
        formattedDate() {
            return this.selectedDay ? this.formatDay(this.selectedDay) + ' of every month' : '';
        },
        formattedSubtitle() {
            if (!this.selectedDay) return '';
            const now = new Date();
            let month = now.getMonth() + 1;
            let year = now.getFullYear();
            const currentDay = now.getDate();
            if (this.selectedDay < currentDay) {
                month += 2;
                if (month > 12) {
                    month = 1;
                    year++;
                }
            } else {
                month++;
            }
            const monthName = new Date(year, month - 1, this.selectedDay).toLocaleString('default', { month: 'long' });
            return ` ${this.formatDay(this.selectedDay)} ${monthName}`;
        }
    },
    methods: {
        togglePopover() {
            this.showPopover = !this.showPopover;
        },
        toggleCalendar() {
            this.showCalendar = !this.showCalendar;
        },
        closeCalendar() {
            this.showCalendar = false;
        },
        selectDay(day) {
            if (this.isDayEnabled(day)) {
                this.selectedDay = day;
                this.$emit('update:modelValue', this.selectedDay);
            }
        },
        confirmDate() {
            this.showCalendar = false;
            this.$emit('update:modelValue', this.selectedDay);
        },
        isDayEnabled(day) {
            return day <= 28;
        },
        formatDay(day) {
            const j = day % 10,
                k = day % 100;
            if (j == 1 && k != 11) {
                return `${day}st`;
            }
            if (j == 2 && k != 12) {
                return `${day}nd`;
            }
            if (j == 3 && k != 13) {
                return `${day}rd`;
            }
            return `${day}th`;
        }
    },
    watch: {
        modelValue(newVal) {
            this.selectedDay = newVal;
        }
    }
}
</script>
