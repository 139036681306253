<template>
  <div class="py-3 container-fluid text-black pt-6">
    <section class="py-3 card">
      <div class="row card-body ">
        <div class=" col-12 me-auto">
          <div class="container pt-4 pb-3 z-index-sticky text-black">
          <breadcrumbs/>
          </div>
  <DetailHeader  title="New Fund Offering" :description="{
    text: 'Step into the Future of Investing with Our Latest NFOs. Each New Fund Offering is a gateway to innovative investment strategies and untapped market potential. Explore this curated selection to find funds that align with your financial goals and help shape your investment future.',
    class: 'pe-md-5 me-md-5 opacity-8',
  }" 
   :firstpoint="{
    text: 'Early Access Advantage',
    class: 'pe-md-5 me-md-5 opacity-8',
  }" 
  :secondpoint="{
    text: 'Potential for Higher Returns ',
    class: 'pe-md-5 me-md-5 opacity-8',
  }"
  :thirdpoint="{
    text: 'Strategic Market Entry',
    class: 'pe-md-5 me-md-5 opacity-8',
  }" mask="mask bg-white opacity-2">
   
  </DetailHeader>
        </div>
      </div>
      <div class="mx-2  row mt-lg-4">
       <nfo-list
       :tblData="tblBody" :mandateData="mandateId" :status="approvalStatus"/>
      </div>
    </section>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import NfoList from "./components/NfoList.vue";
import moment from "moment";
import DetailHeader from "../../views/common/components/DetailHeader.vue";


export default {
  name: "Nfos",
  components: {
    NfoList,
    DetailHeader,
},
  data() {
    return {
    
      tblBody:[],
      mandateId:"",
      approvalStatus:"",
    };
  },
  mounted() {
    //Tool Tip
    setTooltip(this.$store.state.bootstrap);
    this.getnfos();
    this.getAllMandates()
  },
   methods: {
    getAllMandates() {
      var self = this;
      this.$store
        .dispatch("productStore/getMandates", { root: true })
        .then(function (response) {
          self.mandateId = response.server.data[0].mandateCfmId;
          self.approvalStatus = response.server.data[0].approvalStatus || 'PENDING';
          console.log("approval status:",self.approvalStatus);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getnfos()
    {
      var self = this;
      self.$store
        .dispatch("schemeStore/getNfos", self.isin, { root: true })
        .then(function (response) {
           var data = response.server.data;
           var demoTbody=[];
           data.forEach(element => {
            let tblObj = {
              "schemeName":element.scheme.schemeName,
              "schemeType":element.scheme.schemeType,
              "startDate":element.scheme.startDate,
              "endDate":element.scheme.endDate,
              "navValue":element.nav?.navValue?element.nav.navValue:"Not Available",
              "minimumPurchaseAmount":element.scheme.minimumPurchaseAmount,
              "uniqueNumber":element.scheme.uniqueNo,
              "schemeCode":element.scheme.schemeCode,
              "riskometerLaunch":element.scheme.schemeDetails?.riskometerLaunch?element.scheme.schemeDetails?.riskometerLaunch:"Not Available",
              "objective":element.scheme.schemeDetails?.objective?element.scheme.schemeDetails?.objective:"",
              "soon":moment(element.scheme.endDate).diff(moment(),'days')<2
            }
            demoTbody.push(tblObj);
           });
          self.tblBody =  demoTbody.sort(function(a,b){
 
  return  new Date(a.endDate) - new Date(b.endDate) ;
});

        }).catch(function (error) {
          console.log(error);
        });
    },

  }
};
</script>