<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <section>
    <div class="py-5 container">
      <div class="row">
        <div class="mx-auto col-lg-5 col-md-7">
          <div class="mb-5 card shadow">
            <div class="card-body mx-2 px-3 mx-md-4">
              <h4 class="mb-3 font-weight-bolder text-center">New Password</h4>
              <p class="mb-3 ms-2 justify-content-center text-buccaneer">
                Generate a new password, then verify by entering it once more.
              </p>
              <Form role="form" @submit="onSubmit">
                <div class="mb-4">
                  <div class="text-sm text-left p-0 col-12 col-md-9 mb-3 w-100 ms-2">(OTP sent on {{ maskedEmail }})
                  </div>
                  <label class="ones-label-size">OTP</label>
                  <soft-input class="mb-0" placeholder="Enter OTP" v-model="otp" name="otp" id="businessName"
                    type="text" rules="required:true" maxlength="6" />
                </div>
                <div class="mb-5 mb-sm-4">
                  <label class="ones-label-size">New Password</label>
                  <soft-input :id="newpassword" type="password" rules="required:true|password" v-model="password"
                    placeholder="Password" name="newpassword" value=" " autocomplete="off" />
                </div>
                <div class="mb-4">
                  <label class="ones-label-size">Confirm Password</label>
                  <soft-input :id="confirmpassword" class="mb-0" type="password" placeholder="Confirm Password"
                    name="Confirm Password" rules="required:true|confirmed:newpassword" autocomplete="off" />
                </div>
                <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
                </div>
                <div class="text-center">
                  <soft-button color="primary" type="submit" class="my-4 w-100 mb-2">Save and Change
                    Password</soft-button>
                </div>
              </Form>
            </div>
            <div class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row">
              Haven't received it?
              <a id="resend" href="javascript:;" @click="resendOtp"
                class="text-info font-weight-bolder ms-sm-1 mt-2 mt-sm-0">Resend OTP</a>
              <span v-if="timer > 0" class="ms-sm-1 mt-2 mt-sm-0">in {{ timer }} seconds</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="modal" ref="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal- modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="justify-content-center text-center mt-4">
          <i class="fa-regular fa-circle-check fa-5x text-success"></i>
          <h3 class="text-success text-center pe-2 mt-3"> Password Changed</h3>
        </div>
        <div class="modal-body mx-5 ps-4">
          <p class="text-justify text-center">Your password has been changed successfully</p>
        </div>
        <div class="text-center justify-content-center">
          <button type="button" class="btn bg-primary text-white" data-bs-dismiss="modal" @click="redirect">Back to
            Login</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/views/Navbars/Navbar.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "newpassword",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    Form,
  },
  data() {
    return {
      password: "",
      otp: "",
      email: "",
      isSuccess: true,
      count: 60,
      time: "",
      resendEnable: true,
    }
  },
  computed: {
    timer() {
      return this.count;
    },
    maskedEmail() {
      if (this.email) {
        let parts = this.email.split('@');
        let name = parts[0];
        let domain = parts.length > 1 ? parts[1] : '';
        let maskedName = name.substring(0, 1) + name.substring(1).replace(/./g, '*');
        return `${maskedName}@${domain}`;
      }
      return '';
    },
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    clearInterval(this.time);
  },
  mounted() {
    this.$store.state.showFooter = false;
    this.$store.state.showSidenav = false;
    this.email = sessionStorage.getItem("resetPasswordEmail"),
    this.setTime();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    setTime() {
      clearInterval(this.time);
      document.getElementById("resend").classList.remove("text-info");
      document.getElementById("resend").classList.add("text-secondary");
      this.resendEnable = false;
      this.count = 60;
      this.time = setInterval(this.myTimer, 1000);
    },
    myTimer() {
      this.count--;
      if (this.count == 0) {
        document.getElementById("resend").classList.remove("text-secondary");
        document.getElementById("resend").classList.add("text-info");
        this.resendEnable = true;
        clearInterval(this.time);
      }
    },
    onSubmit() {
      let data = {
        "email": this.email,
        "otp": this.otp,
        "password": this.password,
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/newPassword", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          new bootstrap.Modal(self.$refs.modal).show();
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });
    },
    resendOtp() {
      if (!this.resendEnable) return;
      let data = {
        "email": this.email,
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/forgotPassword", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.setTime();
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });
    },
    redirect() {
      this.$router.push({ name: 'Sign In' });
    },
  },
};
</script>
